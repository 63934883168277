import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { VisuallyHidden } from 'react-aria';
import { Button, Label } from 'react-aria-components';

const styles = {
    container: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    `,
    reset: css`
        all: unset;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        box-sizing: border-box;
        outline-color: ${colors.coral2};

        &[data-focus-visible] {
            outline-width: 1.5px;
            outline-offset: 3px;
            outline-style: solid;
        }

        &[data-pressed] {
            transform: scale(0.97);
            transition: transform 0.1s;
        }
    `,
    dot: css`
        width: 6px;
        height: 6px;
        flex-shrink: 0;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px 0 rgba(0 0 0 / 40%);
        background: #c8c8c7;
    `,
    fill: css`
        box-shadow: inset 0 1px 1px 0 rgba(0 0 0 / 40%);
        background: ${colors.coral2};
    `
};

interface DotsProps {
    areaLabel: string;
    items: string[];
    current: number;
    onPress?: (index: number) => void;
}

export const Dots = (props: DotsProps) => {
    const { areaLabel = 'page', items, current = 0, onPress } = props;

    const handleOnPress = (index: number) => () => {
        if (onPress) {
            onPress(index);
        }
    };

    return (
        <div className={cx(styles.container)}>
            <VisuallyHidden>
                <Label>{areaLabel}</Label>
            </VisuallyHidden>
            {items.map((value, index) => (
                <Button
                    onPress={handleOnPress(index)}
                    aria-label={`${areaLabel} ${index}`}
                    key={`${areaLabel ?? 'pagination'}_${value}`}
                    className={cx(styles.reset, styles.dot, current === index && styles.fill)}
                >
                    <VisuallyHidden>
                        <Label>{items ? items[index] : ++index}</Label>
                    </VisuallyHidden>
                </Button>
            ))}
        </div>
    );
};
