import {
    BffConfiguratorConfigurationColorItem,
    IConfiguratorConfigurationColorItemFeatures
} from '@common/graphql/sdk';
import { LanguageSupport, SearchEngine } from 'clientside-search';
import en from 'clientside-search/en';

import { ConfiguratorUiFilters } from './types';

export const createSearchEngineFromColors = (colors: BffConfiguratorConfigurationColorItem[], language: string) => {
    const sLang: Record<string, LanguageSupport> = {
        en: en,
        de: en
    };
    const searchEngine = new SearchEngine(sLang[language] || sLang.en);
    colors.map(({ title, material, colorFamilies, ...rest }) => {
        const materialName = material || '';
        const colorFamiliesNames = colorFamilies?.join(' ') || '';

        searchEngine.addDocument(`${title} ${materialName} ${colorFamiliesNames}`, {
            title,
            material,
            colorFamilies,
            ...rest
        });
    });

    return searchEngine;
};

/**
 * Filters the colors based on the current filters
 * uses the search engine to search as the basis for the filters
 *
 * @param searchEngine
 * @param filters
 * @returns
 */
export const searchForColors = (
    searchEngine: SearchEngine,
    filters: ConfiguratorUiFilters
): BffConfiguratorConfigurationColorItem[] => {
    const colors: BffConfiguratorConfigurationColorItem[] = filters.search
        ? searchEngine.search(filters.search).map((el: any) => el.metadata)
        : Object.values(searchEngine.docMetadata);

    return colors
        .filter((color) => {
            if (!filters?.fabricFamily) {
                return true;
            }
            return filters.fabricFamily.includes(color.material);
        })
        .filter((color) => {
            const [minPriceGroup, maxPriceGroup] = filters?.priceGroup || [null, null];
            if (!minPriceGroup || !maxPriceGroup) {
                return true;
            }

            return (
                color.priceGroup &&
                color.priceGroup >= `PriceGroup${minPriceGroup}` &&
                color.priceGroup <= `PriceGroup${maxPriceGroup}`
            );
        })
        .filter((color) => {
            if (!filters.colorFamily) {
                return true;
            }
            return color.colorFamilies?.some((colorFamily) => filters?.colorFamily?.includes(colorFamily));
        })
        .filter((color) => {
            if (!filters.features || filters.features.length === 0) {
                return true;
            }

            return filters.features.every((feature) =>
                color.features?.includes(feature as IConfiguratorConfigurationColorItemFeatures)
            );
        });
};

/**
 * Restuns the number of active filters
 * @param filters ConfiguratorUiFilters
 * @returns number
 */
export const calculateFilterCount = (filters?: ConfiguratorUiFilters): number => {
    let count = 0;

    if (!filters) {
        return count;
    }

    if (filters?.colorFamily) {
        count += filters.colorFamily.length;
    }

    if (filters?.features) {
        count += filters.features.length;
    }

    if (filters?.priceGroup) {
        count += 1;
    }

    if (filters?.fabricFamily) {
        count += filters.fabricFamily.length;
    }

    return count;
};
