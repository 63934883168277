import { BffConfiguratorConfigurationColor, BffConfiguratorConfigurationColorItem } from '@common/graphql/sdk';
import { emitter } from '@common/hooks/use-events';
import { useMedia } from '@common/hooks/use-media';
import { Expand } from '@common/icons';
import { Button, ButtonIcon } from '@common/primitives';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { MaterialDetail } from '@components/configurator/components/material-detail';
import { Panel } from '@components/configurator/components/panel';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

interface LayerConfigureColorOptionsProps {
    configuration: BffConfiguratorConfigurationColor;
    filteredColors: BffConfiguratorConfigurationColorItem[];
    onChangeColor: (color: BffConfiguratorConfigurationColorItem) => void;
    colorIsSelected: (color: BffConfiguratorConfigurationColorItem) => boolean;
    onClearFilter: () => void;
}

const styles = {
    buttonTileContainer: css`
        display: flex;
    `,
    emptyStateContainer: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;
        height: 100%;
    `,
    emptyStateTitle: css`
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 139%;
        text-align: center;
        margin-bottom: 8px;
    `,
    emptyStateSubtitle: css`
        font-size: 14px;
        text-align: center;
    `,
    emptyStateButtonContainer: css`
        display: flex;
        gap: 8px;
    `
};

export const LayerConfigureColorOptions = ({
    configuration,
    filteredColors,
    onChangeColor,
    onClearFilter,
    colorIsSelected
}: LayerConfigureColorOptionsProps) => {
    const { md } = useMedia();

    const { materials } = configuration;

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

    const [materialDetailColor, setMaterialDetailColor] = useState<BffConfiguratorConfigurationColorItem>();

    const getMaterialName = (materialKey: string) => materials?.find((mat) => mat.id === materialKey)?.name || '';

    const openMaterialDetail = (color: BffConfiguratorConfigurationColorItem) => {
        setMaterialDetailColor(color);
        // signal the legacy frontend to hide breadcrumbs
        emitter.emit('fullscreen:open');
    };

    const closeMaterialDetail = () => {
        setMaterialDetailColor(undefined);
        // signal the legacy frontend to hide breadcrumbs
        emitter.emit('fullscreen:close');
    };

    const options = filteredColors?.map((color) => {
        return (
            <motion.div
                key={color.id}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.25 }}
                className={cx('layer-configure-color-options', styles.buttonTileContainer)}
            >
                <ButtonTile.Item direction={tileDirection}>
                    <ButtonTile.Surface
                        direction={tileDirection}
                        onClick={() => {
                            onChangeColor(color);
                        }}
                        /* we do not use color.selected since colors are cached in the search engine */
                        /* @todo: mabye remove this from the api */
                        selected={colorIsSelected(color)}
                    >
                        <ButtonTile.Image
                            size={thumbnailSize}
                            direction={tileDirection}
                            image={color.listingImage as ButtonTileImageImage}
                        />
                        <ButtonTile.Text
                            size={md ? 'large' : 'default'}
                            direction={tileDirection}
                            title={color.title}
                            subtitle={getMaterialName(color.material)}
                        />
                    </ButtonTile.Surface>
                    <ButtonTile.Info size={thumbnailSize}>
                        <ButtonIcon
                            aria-label={t`Expand`}
                            size="sm"
                            onPress={() => openMaterialDetail(color)}
                            icon={<Expand />}
                        />
                    </ButtonTile.Info>
                </ButtonTile.Item>
            </motion.div>
        );
    });

    const renderEmptyState = () => (
        <div className={styles.emptyStateContainer}>
            <div>
                <div className={styles.emptyStateTitle}>
                    <Trans>No fabrics found</Trans>
                </div>

                <div className={styles.emptyStateSubtitle}>
                    <Trans>Try changing or clearing your filters or search</Trans>
                </div>
            </div>

            <div className={styles.emptyStateButtonContainer}>
                <Button variant="secondary" onPress={onClearFilter}>
                    <Trans>Clear filters and search</Trans>
                </Button>
            </div>
        </div>
    );

    if (!options?.length) {
        return renderEmptyState();
    }

    const material = materials?.find((m) => m.id === materialDetailColor?.material);
    const colorsForMaterial =
        configuration.colors?.filter((color) => color.material === materialDetailColor?.material) || [];

    const optionsElement = (
        <>
            <ButtonTile.List direction={listDirection}>
                <AnimatePresence mode="popLayout" initial={false}>
                    {options}
                </AnimatePresence>
            </ButtonTile.List>
            <MaterialDetail
                onApplyColor={(color) => {
                    onChangeColor(color);
                }}
                material={material}
                onChangeActiveColor={(color) => setMaterialDetailColor(color)}
                activeColor={materialDetailColor}
                colorsForMaterial={colorsForMaterial}
                colorIsSelected={colorIsSelected}
                onClose={() => closeMaterialDetail()}
            />
        </>
    );

    if (md) {
        return optionsElement;
    }

    return <Panel.ScrollArea direction="horizontal">{optionsElement}</Panel.ScrollArea>;
};
