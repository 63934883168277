import { useElementLeavesViewport } from '@common/hooks/use-element-leaves-viewport';
import { useMedia } from '@common/hooks/use-media';
import { Panel, ToolbarMaterials } from '@components/configurator';
import { PADDING_HORIZONTAL } from '@components/configurator/components/panel/constants';
import { useRef } from 'react';

import type { LayerConfigureColorProps } from './layer-configure-color';

interface LayerConfigureColorOptionsDefaultProps extends LayerConfigureColorProps {
    children: React.ReactNode;
    counter: React.ReactNode;
    onComparePress: () => void;
    onSearchPress: () => void;
}

export const LayerConfigureColorOptionsDefault = ({
    children,
    counter,
    configuration,
    searchText,
    filterCount,
    disabledBackButton,
    onClose,
    onToggleFilterLayerById,
    onSearchPress,
    onComparePress
}: LayerConfigureColorOptionsDefaultProps) => {
    const measureRef = useRef(null);

    const { md } = useMedia();

    // we need a delay which is slightly longer than the panel transition
    // Plus we MUST set a rootElement for the intersection observer
    const isSticky = useElementLeavesViewport(
        {
            ref: measureRef,
            offset: -50,
            delay: 500,
            rootElementSelector: '.layer-modular-colors-change, .layer-modular-colors-change-all'
        },
        [measureRef]
    );

    const { id, title } = configuration;

    // If it's sticky, we want to show the small toolbar only on md
    const toolbarAppearance = isSticky ? 'small' : 'large';

    const toolbar = (
        <ToolbarMaterials
            searchBadge={!!searchText}
            filterCount={filterCount}
            onFilterPress={() => onToggleFilterLayerById(id)}
            onSearchPress={onSearchPress}
            onComparePress={onComparePress}
            appearance={md ? toolbarAppearance : 'large'}
        />
    );

    if (md)
        return (
            <>
                <Panel.Navigation isDisabled={disabledBackButton} kind="back" onPress={onClose} />
                <Panel.ScrollArea>
                    <Panel.Title>
                        {title}
                        {counter}
                    </Panel.Title>

                    <Panel.Sticky>
                        <div ref={measureRef} style={{ width: '100%' }}>
                            {toolbar}
                        </div>
                    </Panel.Sticky>
                    {children}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation
                kind="back"
                isDisabled={disabledBackButton}
                appearance="mobile"
                title={title as string}
                onPress={onClose}
            />
            <div style={{ padding: `0 ${PADDING_HORIZONTAL}` }}>{toolbar}</div>
            {children}
        </>
    );
};
