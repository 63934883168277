import {
    BffConfiguratorConfigurationColorItem,
    BffConfiguratorConfigurationColorMaterial,
    ConfiguratorConfigurationItemAttributesFragment
} from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { useElementLeavesViewport } from '@common/hooks/use-element-leaves-viewport';
import { useMedia } from '@common/hooks/use-media';
import { AddElement, ArrowRight, Info } from '@common/icons';
import { ButtonIcon, Tooltip, TooltipTrigger } from '@common/primitives';
import { styles as buttonStyles } from '@common/primitives/button/button';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { icons } from '@components/configurator/components/material-detail/icons';
import { PANEL_WIDTH } from '@components/configurator/components/panel/constants';
import { MODULAR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { Meter } from '@components/material-comparison/components/meter/meter';
import { ROW_WIDTH } from '@components/material-comparison/constants';
import { AttributeKey, AttributeType } from '@components/material-comparison/material-comparison-types';
import translations from '@i18n/variables';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Fragment, useRef, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { Button as DefaultButton, Label } from 'react-aria-components';

import { TableImage } from './table-image';

const styles = {
    table: css`
        width: 100%;
    `,
    tableHeader: css`
        position: sticky;
        top: -1px;
        background-color: ${colors.white};
        z-index: 1;
        padding-left: 14px;
        padding-right: 14px;
        display: block;

        ${media.md} {
            padding-left: 0;
            padding-right: 0;
        }

        tr {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: row;
            gap: 18px;
            padding: 22px 0;

            ${media.md} {
                width: fit-content;
            }

            th {
                flex: 1;
            }
        }
    `,
    tableHeaderSticky: css`
        box-shadow: 0 2px 4px 0 rgba(0 0 0 / 10%);
    `,
    infoButton: css`
        all: unset;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        border: 1px solid #949494;
    `,
    tableBody: css`
        padding-left: 14px;
        padding-right: 14px;
        display: block;

        tr {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            margin-right: auto;

            ${media.md} {
                width: fit-content;
            }

            td {
                flex: 1;
                text-align: left;
                max-width: 100%;

                ${media.md} {
                    width: ${ROW_WIDTH}px;
                }
            }
        }
    `,
    tableBodyThreeRows: css`
        tr {
            ${media.md} {
                width: calc(3 * ${ROW_WIDTH + 14}px);
            }
        }
    `,
    tableBodyFourRows: css`
        tr {
            ${media.md} {
                width: calc(4 * ${ROW_WIDTH + 14}px);
            }
        }
    `,
    tableColumnHeader: css`
        margin-top: 32px;
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%; /* 17.08px */
        padding-bottom: 3px;
        text-align: left;
    `,
    tableRow: css`
        display: flex;
        gap: 36px;
        border-bottom: 1px solid #e6e6e6;
    `,
    tableCell: css`
        padding: 19px 0;
        position: relative;

        &::after {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            right: -18px;
            top: 0;
            background-color: #e6e6e6;
        }

        &:last-child::after {
            content: none;
        }
    `,
    tableCellText: css`
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%;
    `,
    tableCellTextParagraph: css`
        text-align: left;
        padding: 22px 0;
        word-break: break-word;
    `,
    tableCellIcon: css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    `,
    tableColumnGroup: css`
        display: flex;
        align-items: center;
        gap: 6px;
    `,
    priceGroup: css`
        text-align: center;
    `,
    priceGroupSymbol: css`
        font-size: 16px;
        line-height: 130%;
        color: ${colors.coral2};
    `,
    priceGroupSymbolEmpty: css`
        color: #e6e6e6;
    `,
    naturalnessValue: css`
        color: #333;
        font-size: 25px;
        font-weight: 500;
        line-height: 122%;
    `,
    naturalnessLabel: css`
        font-size: 12px;
        font-weight: 500;
        line-height: 122%;
    `,
    emptyImage: css`
        box-shadow: 0 3px 4px 0 rgba(0 0 0 / 8%);
        height: 108px;
        width: ${ROW_WIDTH}px;
        background-color: #f5f5f5;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    emptyContent: css`
        height: 7px;
        width: ${ROW_WIDTH}px;
        background-color: #f5f5f5;
        border-radius: 5px;
        align-self: center;
    `,
    emptyDescription: css`
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 7px;
        padding-top: 22px;

        div {
            align-self: flex-start;
        }
    `,
    pagerButton: css`
        position: absolute;
        top: 54px;
    `,
    pagerButtonLeft: css`
        left: 36px;

        svg {
            transform: rotate(180deg);
        }
    `,
    pagerButtonRight: css`
        right: 36px;
    `
};

export interface MaterialComparisonTableProps {
    currencyCode?: string;
    materials?: BffConfiguratorConfigurationColorMaterial[];
    selectedColors: BffConfiguratorConfigurationColorItem[];
    onClose?: () => void;
    onRemoveColor: (key: string) => void;
}

export const MaterialComparisonTable = ({
    currencyCode = 'EUR',
    materials,
    selectedColors,
    onRemoveColor,
    onClose
}: MaterialComparisonTableProps) => {
    const headerRef = useRef(null);
    const leavesViewport = useElementLeavesViewport({ ref: headerRef, rootElementSelector: '.panel-surface' });

    // Lingui hook used to track translations
    const { _ } = useLingui();
    const { md } = useMedia();
    const { code } = getBffLanguageAndCountry();

    const selectedColorsWithAttributeKeys = selectedColors.map((color) => {
        const material = materials?.find((m) => m.id === color.material);
        return {
            ...color,
            description: material?.text,
            attributes: [...(color?.attributes ?? []), ...(material?.attributes ?? [])]
        };
    });

    // Paging logic
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4; // Always show 4 items per page
    const totalPages = Math.ceil((selectedColorsWithAttributeKeys.length - itemsPerPage) / 1) + 2; // Add one extra page for the empty columns

    const handleNextPage = () => setCurrentPage((page) => Math.min(page + 1, totalPages - 1));
    const handlePreviousPage = () => setCurrentPage((page) => Math.max(page - 1, 0));

    const startIndex = currentPage;
    const endIndex = startIndex + itemsPerPage;
    let pagedColors = selectedColorsWithAttributeKeys.slice(startIndex, endIndex);

    // Ensure 4 items by moving up one item in the array
    if (pagedColors.length < itemsPerPage && selectedColorsWithAttributeKeys.length > itemsPerPage) {
        pagedColors = selectedColorsWithAttributeKeys.slice(startIndex, startIndex + itemsPerPage);
    }

    // Show the empty if we've reached the end of the array or on the extra page
    const isLastPage = currentPage === totalPages - 1;
    const showEmpty = md && (isLastPage || pagedColors.length < itemsPerPage);

    const getCurrencySymbol = () => {
        const parts = new Intl.NumberFormat(code, {
            style: 'currency',
            currency: currencyCode
        }).formatToParts(0);

        const currencySymbol = parts.find((part) => part.type === 'currency')?.value || '';

        return currencySymbol;
    };

    const currencySymbol = getCurrencySymbol();

    const renderTableHeader = () => (
        <tr>
            {md && currentPage > 0 && (
                <th scope="col" className={cx(styles.pagerButton, styles.pagerButtonLeft)}>
                    <ButtonIcon
                        variant="ghost"
                        aria-label={t`Previous`}
                        onPress={handlePreviousPage}
                        icon={<ArrowRight />}
                    />
                </th>
            )}
            {pagedColors.map(({ id, title, material, listingImage }) => {
                const materialName = materials?.find((m) => m.id === material)?.name;
                const imageTitle = `${title}, ${materialName}`;

                return (
                    <th scope="col" key={id}>
                        <TableImage
                            isRemoveButtonShown={selectedColors.length > 2}
                            onRemove={() => {
                                onRemoveColor(id);

                                // Reset the page if we're on the last page
                                if (currentPage === totalPages - 1) {
                                    setCurrentPage(0);
                                }
                            }}
                            title={imageTitle}
                            src={listingImage?.src || ''}
                            shape="rectangle"
                        />
                    </th>
                );
            })}

            {showEmpty && (
                <th>
                    <DefaultButton onPress={onClose} className={cx(buttonStyles.reset, styles.emptyImage)}>
                        <AddElement color={colors.grey} />
                    </DefaultButton>
                </th>
            )}
            {md && currentPage < totalPages - 1 && (
                <th scope="col" className={cx(styles.pagerButton, styles.pagerButtonRight)}>
                    <ButtonIcon
                        variant="ghost"
                        aria-label={t`Previous`}
                        onPress={handleNextPage}
                        icon={<ArrowRight />}
                    />
                </th>
            )}
        </tr>
    );

    const renderPrice = (value: number) => {
        const empty = 5 - value;

        return (
            <div className={styles.priceGroup}>
                <span className={styles.priceGroupSymbol}>{currencySymbol.repeat(value)}</span>
                <span className={cx(styles.priceGroupSymbol, styles.priceGroupSymbolEmpty)}>
                    {currencySymbol.repeat(empty)}
                </span>
            </div>
        );
    };

    const renderTableBody = () => {
        const tooltipStyle = {
            zIndex: MODULAR_MOBILE_MODAL_Z_INDEX + 2,
            maxWidth: PANEL_WIDTH.BASE
        };

        const rows = [
            AttributeKey.EasyToClean,
            AttributeKey.LowMaintenance,
            AttributeKey.DirtResistance,
            AttributeKey.HairResistance,
            AttributeKey.FastnessToRubbing,
            AttributeKey.AbrasionResistance,
            AttributeKey.Pilling,
            AttributeKey.FastnessToLight,
            AttributeKey.TemperatureBehaviour,
            AttributeKey.PricingGroup,
            AttributeKey.Naturalness,
            AttributeKey.AreaOfUse,
            AttributeKey.PlaceOfOrigin,
            AttributeKey.Vegan
        ].map((attribute) => {
            // If none of the colours have the attribute, don't render it
            if (!pagedColors.some((color) => color.attributes.some((attr) => attr.key === attribute))) {
                return null;
            }

            const renderValues = pagedColors.map((color) => {
                const value = color?.attributes?.find(
                    (attr) => attr?.key === attribute
                ) as ConfiguratorConfigurationItemAttributesFragment;

                if (!value) {
                    return <td key={color.id} />;
                }

                // If the attribute is area_of_use
                if (attribute === AttributeKey.AreaOfUse && value?.__typename === AttributeType.Text) {
                    const translationKey = translations[`area_of_use_${value?.textValue}` as keyof typeof translations];
                    const tooltipTranslationKey =
                        translations[`area_of_use_${value?.textValue}_tooltip` as keyof typeof translations];

                    const body = (
                        <>
                            <div className={styles.tableCellIcon}>
                                {value?.textValue === 'residential_use' ? icons.area_of_use : icons.area_of_use_office}
                            </div>

                            <div className={styles.tableCellText}>
                                {translationKey ? _(translationKey) : value?.textValue}
                            </div>
                        </>
                    );

                    return (
                        <td key={color.id} className={styles.tableCell}>
                            {tooltipTranslationKey ? (
                                <TooltipTrigger delay={0}>
                                    {body}
                                    <Tooltip style={tooltipStyle}>
                                        <p>{_(tooltipTranslationKey)}</p>
                                    </Tooltip>
                                </TooltipTrigger>
                            ) : (
                                body
                            )}
                        </td>
                    );
                }

                // If naturalness just show the rawValue
                if (attribute === AttributeKey.Naturalness && value?.__typename === AttributeType.Meter) {
                    return (
                        <td key={color.id} className={styles.tableCell}>
                            <div className={styles.tableCellText}>
                                <span className={styles.naturalnessValue}>{value?.rawValue}</span>
                                <span className={styles.naturalnessLabel}>%</span>
                            </div>
                        </td>
                    );
                }

                // If the attribute is place_of_origin
                if (attribute === AttributeKey.PlaceOfOrigin && value?.__typename === AttributeType.Text) {
                    const translationKey =
                        translations[`place_of_origin_${value?.textValue}` as keyof typeof translations];

                    return (
                        <td key={color.id} className={styles.tableCell}>
                            <div className={styles.tableCellIcon}>
                                {icons[`place_of_origin_${value?.textValue}` as keyof typeof icons]}
                            </div>

                            <div className={styles.tableCellText}>
                                {translationKey ? _(translationKey) : value?.textValue}
                            </div>
                        </td>
                    );
                }

                // If it's pricing group, return the currency sign based on the value
                if (attribute === AttributeKey.PricingGroup && value?.__typename === AttributeType.Meter) {
                    return (
                        <td key={color.id} className={styles.tableCell}>
                            {renderPrice(value?.meterValue)}
                        </td>
                    );
                }

                if (value?.__typename === AttributeType.Meter) {
                    const translationKey =
                        translations[`${attribute}_${value?.meterValue}` as keyof typeof translations];

                    return (
                        <td key={color.id} className={styles.tableCell}>
                            {translationKey ? (
                                <TooltipTrigger delay={0}>
                                    <Meter labelId={attribute} value={value?.meterValue} />
                                    <Tooltip style={tooltipStyle}>
                                        <p>{_(translationKey)}</p>
                                    </Tooltip>
                                </TooltipTrigger>
                            ) : (
                                <Meter labelId={attribute} value={value?.meterValue} />
                            )}
                        </td>
                    );
                }

                // If the attribute is vegan
                if (attribute === AttributeKey.Vegan && value?.__typename === AttributeType.Boolean) {
                    const translationKey = translations[`vegan_${value?.booleanValue}` as keyof typeof translations];

                    return (
                        <td className={styles.tableCell} key={color.id}>
                            <div className={styles.tableCellIcon}>
                                {value?.booleanValue ? icons.vegan2 : icons.misc}
                            </div>

                            <div className={styles.tableCellText}>
                                {translationKey ? _(translationKey) : value?.booleanValue}
                            </div>
                        </td>
                    );
                }

                return <td key={color.id} />;
            });

            const attributeTranslationKey = translations[attribute as keyof typeof translations];
            const tooltipTranslationKey = translations[`${attribute}_tooltip` as keyof typeof translations];

            return (
                <Fragment key={attribute}>
                    <tr className={styles.tableColumnHeader}>
                        <th className={styles.tableColumnGroup} scope="colgroup" colSpan={4}>
                            <Label id={attribute}>
                                {attributeTranslationKey ? _(attributeTranslationKey) : attribute}
                            </Label>
                            {tooltipTranslationKey && (
                                <TooltipTrigger delay={0}>
                                    <Tooltip style={tooltipStyle}>
                                        <p>{tooltipTranslationKey ? _(tooltipTranslationKey) : attribute}</p>
                                    </Tooltip>
                                    <div className={styles.infoButton}>
                                        <Info />
                                    </div>
                                </TooltipTrigger>
                            )}
                        </th>
                    </tr>

                    <tr className={styles.tableRow}>
                        {renderValues}
                        {showEmpty && <td className={styles.emptyContent} />}
                    </tr>
                </Fragment>
            );
        });

        return rows;
    };

    const renderDescription = () => {
        // @todo: Tab view for mobile
        if (!md) return null;

        return (
            <>
                <tr className={styles.tableColumnHeader}>
                    <th className={styles.tableColumnGroup} scope="colgroup" colSpan={4}>
                        <Label id={'description-label'}>{_(translations.description)}</Label>
                    </th>
                </tr>
                <tr className={styles.tableRow}>
                    {pagedColors.map((color) => {
                        const key = `${color.id}_desc`;

                        return (
                            <td key={key}>
                                <p className={cx(styles.tableCellText, styles.tableCellTextParagraph)}>
                                    {color.description}
                                </p>
                            </td>
                        );
                    })}

                    {showEmpty && (
                        <td className={styles.emptyDescription}>
                            <div
                                className={styles.emptyContent}
                                style={{
                                    width: '75%'
                                }}
                            />
                            <div
                                className={styles.emptyContent}
                                style={{
                                    width: '50%'
                                }}
                            />
                            <div
                                className={styles.emptyContent}
                                style={{
                                    width: '36%'
                                }}
                            />
                            <div
                                className={styles.emptyContent}
                                style={{
                                    width: '70%'
                                }}
                            />
                            <div
                                className={styles.emptyContent}
                                style={{
                                    width: '50%'
                                }}
                            />
                            <div
                                className={styles.emptyContent}
                                style={{
                                    width: '80%'
                                }}
                            />
                        </td>
                    )}
                </tr>
            </>
        );
    };

    return (
        <table className={styles.table}>
            <caption>
                <VisuallyHidden>
                    <Trans>Compare Fabrics</Trans>
                </VisuallyHidden>
            </caption>
            <thead
                ref={headerRef}
                className={cx(
                    styles.tableHeader,
                    leavesViewport && styles.tableHeaderSticky,
                    md && selectedColors.length <= 2 && styles.tableBodyThreeRows,
                    md && selectedColors.length >= 3 && styles.tableBodyFourRows
                )}
            >
                {renderTableHeader()}
            </thead>
            <tbody
                className={cx(
                    styles.tableBody,
                    md && selectedColors.length <= 2 && styles.tableBodyThreeRows,
                    md && selectedColors.length >= 3 && styles.tableBodyFourRows
                )}
            >
                {renderTableBody()}
                {renderDescription()}
            </tbody>
        </table>
    );
};
