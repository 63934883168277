import { msg } from '@lingui/macro';

const variables = {
    // Boolean
    vegan: msg`Vegan`,
    vegan_true: msg`Yes`,
    vegan_false: msg`No`,
    vegan_tooltip: msg`Vegan tooltip`,

    // Scales
    abrasion_resistance: msg`Abrasion resistance`,
    abrasion_resistance_1: msg`abrasion_resistance_1`,
    abrasion_resistance_2: msg`abrasion_resistance_2`,
    abrasion_resistance_3: msg`abrasion_resistance_3`,
    abrasion_resistance_4: msg`abrasion_resistance_4`,
    abrasion_resistance_5: msg`abrasion_resistance_5`,
    abrasion_resistance_tooltip: msg`Abrasion resistance tooltip`,

    easy_to_clean: msg`Easy to clean`,
    easy_to_clean_1: msg`easy_to_clean_1`,
    easy_to_clean_2: msg`easy_to_clean_2`,
    easy_to_clean_3: msg`easy_to_clean_3`,
    easy_to_clean_4: msg`easy_to_clean_4`,
    easy_to_clean_5: msg`easy_to_clean_5`,
    easy_to_clean_tooltip: msg`Easy to clean tooltip`,

    dirt_resistance: msg`Dirt resistance`,
    dirt_resistance_1: msg`dirt_resistance_1`,
    dirt_resistance_2: msg`dirt_resistance_2`,
    dirt_resistance_3: msg`dirt_resistance_3`,
    dirt_resistance_4: msg`dirt_resistance_4`,
    dirt_resistance_5: msg`dirt_resistance_5`,
    dirt_resistance_tooltip: msg`Dirt resistance tooltip`,

    fastness_to_light: msg`Fastness to light`,
    fastness_to_light_1: msg`fastness_to_light_1`,
    fastness_to_light_2: msg`fastness_to_light_2`,
    fastness_to_light_3: msg`fastness_to_light_3`,
    fastness_to_light_4: msg`fastness_to_light_4`,
    fastness_to_light_5: msg`fastness_to_light_5`,
    fastness_to_light_tooltip: msg`Fastness to light tooltip`,

    fastness_to_rubbing: msg`Fastness to rubbing`,
    fastness_to_rubbing_1: msg`fastness_to_rubbing_1`,
    fastness_to_rubbing_2: msg`fastness_to_rubbing_2`,
    fastness_to_rubbing_3: msg`fastness_to_rubbing_3`,
    fastness_to_rubbing_4: msg`fastness_to_rubbing_4`,
    fastness_to_rubbing_5: msg`fastness_to_rubbing_5`,
    fastness_to_rubbing_tooltip: msg`Fastness to rubbing tooltip`,

    hair_resistance: msg`Hair resistance`,
    hair_resistance_1: msg`hair_resistance_1`,
    hair_resistance_2: msg`hair_resistance_2`,
    hair_resistance_3: msg`hair_resistance_3`,
    hair_resistance_4: msg`hair_resistance_4`,
    hair_resistance_5: msg`hair_resistance_5`,
    hair_resistance_tooltip: msg`Hair resistance tooltip`,

    low_maintenance: msg`Low maintenance`,
    low_maintenance_1: msg`low_maintenance_1`,
    low_maintenance_2: msg`low_maintenance_2`,
    low_maintenance_3: msg`low_maintenance_3`,
    low_maintenance_4: msg`low_maintenance_4`,
    low_maintenance_5: msg`low_maintenance_5`,
    low_maintenance_tooltip: msg`Low maintenance tooltip`,

    naturalness: msg`Naturalness`,
    naturalness_tooltip: msg`Naturalness tooltip`,

    pilling: msg`Pilling`,
    pilling_1: msg`Grade 1`,
    pilling_2: msg`Grade 2`,
    pilling_3: msg`Grade 3`,
    pilling_4: msg`Grade 4`,
    pilling_5: msg`Grade 5`,
    pilling_tooltip: msg`Pilling tooltip`,

    pricing_group: msg`Price group`,
    pricing_group_1: msg`pricing_group_1`,
    pricing_group_2: msg`pricing_group_2`,
    pricing_group_3: msg`pricing_group_3`,
    pricing_group_4: msg`pricing_group_4`,
    pricing_group_5: msg`pricing_group_5`,
    pricing_group_tooltip: msg`Pricing group tooltip`,

    temperature_behaviour: msg`Temperature behaviour`,
    temperature_behaviour_1: msg`temperature_behaviour_1`,
    temperature_behaviour_2: msg`temperature_behaviour_2`,
    temperature_behaviour_3: msg`temperature_behaviour_3`,
    temperature_behaviour_4: msg`temperature_behaviour_4`,
    temperature_behaviour_5: msg`temperature_behaviour_5`,
    temperature_behaviour_tooltip: msg`Temperature behaviour tooltip`,

    // Enums
    area_of_use: msg`Area of use`,
    area_of_use_tooltip: msg`area_of_use_tooltip`,
    area_of_use_home: msg`Home`,
    area_of_use_home_tooltip: msg`area_of_use_home_tooltip`,
    area_of_use_heavy_use: msg`Heavy use`,
    area_of_use_heavy_use_tooltip: msg`area_of_use_heavy_use_tooltip`,
    area_of_use_office: msg`Office`,
    area_of_use_office_tooltip: msg`area_of_use_office_tooltip`,
    area_of_use_medium_use: msg`Medium use`,
    area_of_use_medium_use_tooltip: msg`area_of_use_medium_use_tooltip`,
    area_of_use_home_office: msg`Home office`,
    area_of_use_home_office_tooltip: msg`area_of_use_home_office_tooltip`,
    area_of_use_residential_use: msg`Residential use`,
    area_of_use_residential_use_tooltip: msg`area_of_use_residential_use_tooltip`,
    area_of_use_soft_contract: msg`Soft contract`,
    area_of_use_soft_contract_tooltip: msg`area_of_use_soft_contract_tooltip`,

    material: msg`Material`,
    material_wool: msg`Wool`,
    material_nylon: msg`Nylon`,
    material_newwool: msg`New wool`,
    material_cotton: msg`Cotton`,
    material_polyamide: msg`Polyamide`,
    material_polypropylene: msg`Polypropylene`,
    material_polyester: msg`Polyester`,
    material_polyacrylics: msg`Polyacrylics`,
    material_spandex: msg`Spandex`,
    material_linen: msg`Linen`,
    material_polyurethane: msg`Polyurethane`,
    material_helanca: msg`Helanca`,
    material_recycledpolyester: msg`Recycled polyester`,
    material_pvc: msg`PVC`,
    material_polyester_trevire_cs: msg`Polyester Trevira CS`,
    material_rayon: msg`Rayon`,

    place_of_origin: msg`Place of origin`,
    place_of_origin_DE: msg`Germany`,
    place_of_origin_EU: msg`European Union`,
    place_of_origin_GB: msg`Great Britain`,
    place_of_origin_HU: msg`Hungary`,
    place_of_origin_IT: msg`Italy`,
    place_of_origin_NL: msg`Netherlands`,
    place_of_origin_US: msg`United States`,
    place_of_origin_BE: msg`Belgium`,
    place_of_origin_ES: msg`Spain`,
    place_of_origin_tooltip: msg`Place of origin tooltip`,

    // Text
    width: msg`Width`,
    weight: msg`Weight`,
    description: msg`Description`
};

export default variables;
