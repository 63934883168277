import { BffConfiguratorConfigurationColor, BffConfiguratorConfigurationColorItem } from '@common/graphql/sdk';
import { unmarshalSKU } from '@common/utils/sku';
import { allColorsIds, LayerId, useModularConfigurator } from '@components/configurator/hooks';
import { useViewer } from '@components/configurator/hooks/use-viewer';
import { useEffect, useState } from 'react';

import { LayerConfigureColor } from '../layer-configure/layer-configure-color/layer-configure-color';

export const LayerModularColorsChange = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const colors = useModularConfigurator((state) => state.initialData?.colors) || [];
    const materials = useModularConfigurator((state) => state.initialData?.materials) || [];
    const filters = useModularConfigurator((state) => state.ui.filters);
    const onSetFilter = useModularConfigurator((state) => state.onSetFilter);
    const onClearFilter = useModularConfigurator((state) => state.onClearFilter);
    const setSecondaryLayer = useModularConfigurator((state) => state.setSecondaryLayer);
    const clearSecondaryLayer = useModularConfigurator((state) => state.clearSecondaryLayer);

    const getFilteredColors = useModularConfigurator((state) => state.getFilteredColors);
    const getFilterCount = useModularConfigurator((state) => state.getFilterCount);
    const getProductByNodeLocalId = useModularConfigurator((state) => state.getProductByNodeLocalId);
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);
    const isBlocked = useModularConfigurator((state) => state.ui.isBlocked);

    const setModularConfiguratorFurnitureProductConfiguration = useViewer(
        (state) => state.setModularConfiguratorFurnitureProductConfiguration
    );

    const activeData = useModularConfigurator((state) => state.activeData);
    if (!activeData) {
        return null;
    }

    const currentLayer = primaryStack.at(-1);
    const nodeAndConfiguration = currentLayer?.replace('change-node-colors-', '') || '';
    const [nodeId] = nodeAndConfiguration.split('-');
    const product = getProductByNodeLocalId(nodeId ?? '');

    // Duplicate the allow optimistic updates
    const [activeSku, setActiveSKU] = useState<string | null>(null);

    useEffect(() => {
        setActiveSKU(product?.sku ?? null);
    }, [product?.sku]);

    if (!product) {
        return null;
    }

    const onToggleFilterLayerById = (id?: string) => {
        if (id) {
            setSecondaryLayer(id as LayerId);
        } else {
            clearSecondaryLayer();
        }
    };

    const filterCount = getFilterCount();

    const getColorMapForProduct = useModularConfigurator((state) => state.getColorMapForProduct);
    const colorMap = getColorMapForProduct(product);

    const filteredColors = getFilteredColors().filter((color) => colorMap.has(color.id));
    const productColors = colors.filter((color) => colorMap.has(color.id));

    const updateConfiguration = async (color: BffConfiguratorConfigurationColorItem) => {
        const matchingSku = colorMap.get(color.id);
        if (matchingSku) {
            setActiveSKU(matchingSku.sku);
            setIsBlocked(true);
            const { configurationId } = unmarshalSKU(matchingSku.sku);
            if (configurationId) {
                await setModularConfiguratorFurnitureProductConfiguration(product.node.localId, configurationId);
            }
        }
    };

    const colorIsSelected = (color: BffConfiguratorConfigurationColorItem): boolean => {
        const matchingSku = colorMap.get(color.id);
        return matchingSku?.sku === activeSku;
    };

    const configuration: BffConfiguratorConfigurationColor = {
        colors: productColors,
        materials,
        id: allColorsIds,
        type: 'color',
        title: product.name
    };

    return (
        <LayerConfigureColor
            disabledBackButton={isBlocked}
            configuration={configuration}
            filteredColors={filteredColors}
            filterCount={filterCount}
            searchText={filters?.search || ''}
            onChangeColor={updateConfiguration}
            onClose={popLayer}
            colorIsSelected={colorIsSelected}
            onSetFilter={onSetFilter}
            onClearFilter={onClearFilter}
            onToggleFilterLayerById={onToggleFilterLayerById}
        />
    );
};
