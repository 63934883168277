import {
    BffConfiguratorConfigurationColorItem,
    BffConfiguratorConfigurationColorMaterial,
    IConfiguratorConfigurationColorItemColorFamily,
    IConfiguratorConfigurationColorItemFeatures
} from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Button } from '@common/primitives';
import { Separator } from '@common/primitives/separator';
import { colorFamilies, colors as themeColors } from '@common/styles/colors';
import { Panel } from '@components/configurator/components/panel';
import { PriceSlider } from '@components/configurator/components/price-slider';
import { calculateFilterCount, FilterKey } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { t } from '@lingui/macro';
import { Group, Selection } from 'react-aria-components';

import {
    LayerConfigureColorFilterGroup,
    LayerConfigureColorFilterGroupColour
} from './layer-configure-color-filter-group';

const styles = {
    container: css`
        padding-top: 24px;
    `,
    group: css`
        margin: 28px 0 22px;
        display: flex;
        justify-content: center;
    `,
    section: css`
        width: 100%;

        button {
            width: 100%;
        }
    `,
    colorFamilyThumbnail: css`
        width: 48px;
        height: 48px;
        border-radius: 100%;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;

        &::before {
            content: '';
            width: 50%;
            height: 100%;
            background-color: ${themeColors.white};
            opacity: 0.8;
            mix-blend-mode: soft-light;
        }
    `
};

interface LayerConfigureColorFilterProps {
    onClearFilter: () => void;
    onSetFilter: (attribute: FilterKey, filter: string | string[] | undefined) => void;
    onClose: () => void;

    colors: BffConfiguratorConfigurationColorItem[];
    materials: BffConfiguratorConfigurationColorMaterial[];

    activeFilters?: {
        features?: string[];
        colorFamily?: string[];
        fabricFamily?: string[];
        priceGroup?: [string, string];
    };

    resultCount: number;
}

export const LayerConfigureColorFilter = ({
    onClose,
    onClearFilter,
    onSetFilter,
    colors,
    materials,
    activeFilters,
    resultCount
}: LayerConfigureColorFilterProps) => {
    const { md } = useMedia();

    const translations = {
        cooling: t`Cooling`,
        cosy: t`Cosy`,
        direct_sunlight: t`Direct sunlight`,
        fabric: t`Fabric`,
        family_friendly: t`Family friendly`,
        leather: t`Leather`,
        natural: t`Natural`,
        patina: t`Patina`,
        pet_friendly: t`Pet friendly`,
        robust_choice: t`Robust choice`,
        sensitive_skin: t`Sensitive skin`,
        stain_resistant: t`Stain resistant`,
        vegan: t`vegan-feature`
    };

    const renderColorFamilyThumbnail = (family: IConfiguratorConfigurationColorItemColorFamily) => {
        return (
            <div
                className={styles.colorFamilyThumbnail}
                style={{
                    backgroundColor: colorFamilies[family]
                }}
            />
        );
    };

    const fabricFamilyOptions = [...new Set(colors?.flatMap((color) => color.material))].map((fabricFamily) => {
        const materialName = materials?.find((mat) => mat.id === fabricFamily)?.name || '';
        return {
            id: fabricFamily,
            label: materialName
        };
    });

    const fabricFamilySelected = activeFilters?.fabricFamily?.map(String) ?? [];

    const colorFamilyOptions = [...new Set(colors?.flatMap((color) => color.colorFamilies))].map((colorFamily) => ({
        id: colorFamily as string,
        label: colorFamily as string,
        image: colorFamily && renderColorFamilyThumbnail(colorFamily as IConfiguratorConfigurationColorItemColorFamily)
    }));

    const colorFamilySelected = activeFilters?.colorFamily ?? '';

    // Base the list on enum
    const featureOptions = Object.values(IConfiguratorConfigurationColorItemFeatures).map((feature) => ({
        id: feature as string,
        label: translations[feature as keyof typeof translations]
    }));

    const featureSelected = activeFilters?.features ?? [];

    const handlePriceChange = (value: [number, number]) => {
        const [min, max] = value;

        // Map the min and max values to the corresponding price groups
        const minPriceGroup = `${(min + 1) * 10}`;
        const maxPriceGroup = `${(max + 1) * 10}`;

        const priceGroup = min === 0 && max === 4 ? undefined : [minPriceGroup, maxPriceGroup];
        onSetFilter('priceGroup', priceGroup);
    };

    const handleFabricFamilyChange = (selection: Selection) => {
        const fabricFamily = selection === 'all' || selection.size === 0 ? undefined : [...selection].map(String);
        onSetFilter('fabricFamily', fabricFamily);
    };

    const handleColorFamilyChange = (selection: Selection) => {
        const colorFamily = selection === 'all' || selection.size === 0 ? undefined : [...selection].map(String);
        onSetFilter('colorFamily', colorFamily);
    };

    const handleFeatureChange = (selection: Selection) => {
        const features = selection === 'all' || selection.size === 0 ? undefined : [...selection].map(String);
        onSetFilter('features', features);
    };

    // Show Footer on not fitler
    const hasFooter = calculateFilterCount(activeFilters) > 0;

    const footer = md ? (
        hasFooter && (
            <Panel.Footer>
                <div className={styles.section}>
                    <Button variant="secondary" onPress={onClearFilter}>{t`Clear all`}</Button>
                </div>
            </Panel.Footer>
        )
    ) : (
        <>
            <Panel.Footer>
                <Button variant="secondary" onPress={onClearFilter}>{t`Clear all`}</Button>
                <Button variant="coral" onPress={onClose}>{t`Show ${resultCount}`}</Button>
            </Panel.Footer>
            <Panel.Navigation kind="close" onPress={onClose} />
        </>
    );

    return (
        <>
            {md && <Panel.Navigation kind="close" onPress={onClose} />}
            <Panel.ScrollArea className={styles.container}>
                <div className={styles.section}>
                    <Group className={styles.group}>
                        <LayerConfigureColorFilterGroupColour
                            label={t`Colour`}
                            selectionMode="multiple"
                            onSelectionChange={handleColorFamilyChange}
                            items={colorFamilyOptions}
                            selectedKeys={colorFamilySelected || []}
                        />
                    </Group>

                    <Separator orientation="horizontal" />
                </div>

                <div className={styles.section}>
                    <Group className={styles.group}>
                        <LayerConfigureColorFilterGroup
                            selectionMode="multiple"
                            label={t`Features`}
                            onSelectionChange={handleFeatureChange}
                            selectedKeys={featureSelected}
                            items={featureOptions}
                        />
                    </Group>

                    <Separator orientation="horizontal" />
                </div>

                <div className={styles.section}>
                    <Group className={styles.group}>
                        <PriceSlider
                            value={
                                activeFilters?.priceGroup
                                    ? [
                                          Number(activeFilters.priceGroup[0]) / 10 - 1,
                                          Number(activeFilters.priceGroup[1]) / 10 - 1
                                      ]
                                    : [0, 4]
                            }
                            onChange={handlePriceChange}
                        />
                    </Group>

                    <Separator orientation="horizontal" />
                </div>

                <div className={styles.section}>
                    <Group className={styles.group}>
                        <LayerConfigureColorFilterGroup
                            selectionMode="multiple"
                            label={t`Fabric family`}
                            selectedKeys={fabricFamilySelected}
                            onSelectionChange={handleFabricFamilyChange}
                            items={fabricFamilyOptions}
                        />
                    </Group>
                </div>
            </Panel.ScrollArea>
            {footer}
        </>
    );
};
