import { useConfig } from '@common/hooks/use-config';
import { useMedia } from '@common/hooks/use-media';
import { Share, Tick } from '@common/icons';
import { Button } from '@common/primitives/button';
import { colors } from '@common/styles/colors';
import { MODULAR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useShoppingList } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { Trans } from '@lingui/macro';
import clipboardCopy from 'clipboard-copy';
import { useEffect, useState } from 'react';
import { Key, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';

const styles = {
    popover: css`
        border-radius: 6px;
        background: ${colors.white};
        box-shadow: 0 1.867px 3.734px 0 rgba(0 0 0 / 15%);
        display: flex;
        flex-direction: column;
        padding-top: 7px;
        padding-bottom: 15px;
        outline-color: ${colors.coral2};
        z-index: ${MODULAR_MOBILE_MODAL_Z_INDEX + 1} !important;
    `,
    menu: css`
        outline: none;

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
        }
    `,
    menuItem: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        padding: 9px 24px;
        font-size: 13px;
        font-weight: 400;
        line-height: 122%;
        outline: none;

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
        }

        &[data-hovered] {
            cursor: pointer;
            color: ${colors.coral2};
        }
    `
};

export const ShareMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const { md } = useMedia();
    const shareShoppingList = useShoppingList((state) => state.shareShoppingList);

    const handleCopy = async () => {
        const share = await shareShoppingList();
        if (share) {
            clipboardCopy(share);
            setIsCopied(true);
        }
        setIsCopied(true);
    };

    const handleShare = async () => {
        const findVitraUrl = useConfig('findVitraUrl', '');
        if (findVitraUrl) {
            window.open(`${findVitraUrl}?showShareWithRetailer=true`, '_blank');
        }
    };

    const handleAction = (action: Key) => {
        switch (action) {
            case 'close':
                setIsOpen(false);
                break;
            case 'share-with-retailer':
                handleShare();
                break;
            case 'copy-link':
                handleCopy();
                break;
        }
    };

    useEffect(() => {
        if (!isOpen && isCopied) {
            setIsCopied(false);
        }
    }, [isOpen]);

    return (
        <MenuTrigger isOpen={isOpen}>
            <Button variant="secondary" rounded onPress={() => setIsOpen(!isOpen)}>
                <Share />
                <Trans>Share list</Trans>
            </Button>

            <Popover
                placement={md ? 'bottom left' : 'bottom'}
                className={styles.popover}
                shouldCloseOnInteractOutside={() => {
                    setIsOpen(false);
                    return true;
                }}
            >
                <Menu className={styles.menu} onAction={handleAction}>
                    <MenuItem className={styles.menuItem} id={'share-with-retailer'}>
                        <Trans>Share with retailer</Trans>
                    </MenuItem>
                    <MenuItem className={styles.menuItem} id={'copy-link'}>
                        {isCopied ? (
                            <>
                                <Tick color={colors.coral2} />
                                <Trans>Link copied</Trans>
                            </>
                        ) : (
                            <Trans>Copy link</Trans>
                        )}
                    </MenuItem>
                </Menu>
            </Popover>
        </MenuTrigger>
    );
};
