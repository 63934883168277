import { media } from '@common/styles/media';
import { Viewer } from '@components/configurator';
import { PANEL_WIDTH } from '@components/configurator/components/panel/constants';
import { ToolbarDesktop } from '@components/configurator/components/toolbar';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { useEffect } from 'react';

const PANEL_HEIGHT = 235;

const styles = {
    viewer: css`
        height: calc(100dvh + ${PANEL_HEIGHT}px);
        transform: translateY(-${PANEL_HEIGHT}px);
        width: 100vw;
        position: absolute;
        z-index: 0;

        .viewer-progress {
            bottom: ${PANEL_HEIGHT}px;

            ${media.md} {
                bottom: calc(24px + ${PANEL_HEIGHT}px);
            }
        }

        ${media.md} {
            z-index: 0;
            position: absolute;
            height: 100%;
            width: calc(100vw + ${PANEL_WIDTH.BASE}px);
            transform: translateX(calc(((-100vw + 1600px) / 2) - ${PANEL_WIDTH.BASE}px));
            overflow: hidden;
            will-change: transform;

            .viewer-progress {
                bottom: 51px;
            }
        }
    `,
    viewerFooter: css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 31px;
        display: flex;
        z-index: 2;
    `
};

export const ConfiguratorStandardViewer = () => {
    const loadViewer = useViewer((state) => state.loadViewer);
    const updateViewer = useViewer((state) => state.updateViewer);
    const destroyViewer = useViewer((state) => state.destroyViewer);
    const viewer = useViewer((state) => state.viewer);
    const loaded = useViewer((state) => state.loaded);
    const data = useConfigurator((state) => state.data);
    const { image, sku } = data || {};

    useEffect(() => {
        if (viewer && sku) {
            updateViewer(sku);
            return;
        }

        if (sku) {
            loadViewer(sku);
        }
    }, [sku, viewer]);

    useEffect(() => {
        // only call this on unmount
        return () => {
            destroyViewer();
        };
    }, []);

    if (!data) {
        return null;
    }

    return (
        <div className={styles.viewer}>
            <Viewer placeholderUrl={loaded ? undefined : image?.src}>
                <div className={styles.viewerFooter}>
                    <ToolbarDesktop />
                </div>
            </Viewer>
        </div>
    );
};
