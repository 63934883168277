import { BffPrice, Maybe } from '@common/graphql/sdk';
import { Tick } from '@common/icons';
import { Tooltip, TooltipTrigger } from '@common/primitives';
import { MarqueeText } from '@common/primitives/marquee-text';
import { colors } from '@common/styles/colors';
import { formatPrice } from '@common/utils/format-price';
import { MODULAR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

interface HeaderProps {
    name?: string;
    price?: Maybe<BffPrice>;
    shipping?: string;
    className?: string;
}

const styles = {
    header: css`
        display: flex;
        flex-direction: column;
        gap: 2px;
    `,
    headerText: css`
        font-size: 16px;
        line-height: 130%;
    `,
    headerHeading: css`
        color: #000;
        font-weight: 600;
    `,
    headerBody: css`
        display: flex;
        gap: 12px;
    `,
    headerBodyText: css`
        color: #333;
        font-weight: 400;
    `,
    headerSeparator: css`
        width: 1px;
        height: 18px;
        background: #d9d9d9;
    `,
    headerTooltip: css`
        display: flex;
        gap: 9px;
        align-items: center;
    `
};

export const HeaderPrice = ({ price }: { price: BffPrice }) => {
    if (!price || !price.currencyCode || !price.amount) {
        return null;
    }

    const { i18n } = useLingui();
    const formattedPrice = formatPrice(price, i18n);
    return <span className={cx(styles.headerText, styles.headerBodyText)}>{formattedPrice}</span>;
};

export const Header = (props: HeaderProps) => {
    const { name, price, shipping, className } = props;

    return (
        <header className={cx(styles.header, className)}>
            {price && name && (
                <h2 className={cx('header-title', styles.headerText, styles.headerHeading)}>
                    <MarqueeText active={true}>{name}</MarqueeText>
                </h2>
            )}
            {(price || shipping) && (
                <div className={cx('header-body')}>
                    <TooltipTrigger>
                        <div className={styles.headerBody}>
                            {price && <HeaderPrice price={price} />}
                            {price && shipping && <span className={cx(styles.headerSeparator)} />}
                            <span className={cx(styles.headerText, styles.headerBodyText)}>{shipping}</span>
                        </div>
                        <Tooltip
                            style={{
                                zIndex: MODULAR_MOBILE_MODAL_Z_INDEX + 1
                            }}
                        >
                            <span className={cx(styles.headerTooltip)}>
                                <Tick color={colors.coral2} />

                                <span>
                                    <Trans>Including tax</Trans>
                                </span>
                                <span className={cx(styles.headerSeparator)} />
                                <span>
                                    <Trans>Excluding shipping</Trans>
                                </span>
                            </span>
                        </Tooltip>
                    </TooltipTrigger>
                </div>
            )}
        </header>
    );
};
