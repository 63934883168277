import {
    BffConfiguratorConfigurationColorItem,
    BffConfiguratorConfigurationColorMaterial,
    BffConfiguratorConfigurationItemAttributeText
} from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Button, Separator } from '@common/primitives';
import { MarqueeText } from '@common/primitives/marquee-text';
import { colors as themeColors } from '@common/styles/colors';
import { AttributeKey, AttributeType } from '@components/material-comparison/material-comparison-types';
import translations from '@i18n/variables';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useRef, useState } from 'react';

import { ButtonTile } from '../button-tile';
import { Panel } from '../panel';
import { icons } from './icons';

const styles = {
    container: css`
        padding: 0;
        max-width: 100%;
        overflow-x: hidden;
    `,
    title: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px;
    `,
    section: css`
        display: flex;
        flex-direction: column;
    `,
    sectionPadded: css`
        padding-left: 22px;
        padding-right: 22px;
        padding-bottom: 52px;
    `,
    sectionTitle: css`
        display: flex;
        gap: 8px;
        align-items: center;
        color: ${themeColors.grey};
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%; /* 13.42px */
        letter-spacing: 1.32px;
        text-transform: uppercase;
        margin-bottom: 18px;
    `,
    sectionTitleSeparator: css`
        display: inline-flex;
        width: 1px;
        height: 100%;
        background-color: #e6e6e6; /**  Todo: Replace with token */
    `,
    sectionDescription: css`
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #333;
        padding-bottom: 44px;
    `,
    sectionButton: css`
        display: flex;
        padding-top: 30px;
        margin: auto;
    `,
    detail: css`
        display: flex;
        align-items: center;
        gap: 17px;
        padding: 30px 52px 30px 3px;
    `,
    detailText: css`
        display: flex;
        flex-direction: column;
        gap: 5px;
    `,
    detailTextTitle: css`
        color: ${themeColors.black};
        font-size: 14px;
        font-weight: 600;
        line-height: 122%;
    `,
    detailTextDescription: css`
        color: ${themeColors.black};
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    `,
    colorGrid: css`
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 10px;
        padding: 22px 0;
        width: 100%;
    `,
    colorGridItemTitle: css`
        color: ${themeColors.black};
        padding-top: 10px;
        padding-bottom: 14px;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.065px;
    `,
    colorGridItemTitleSelected: css`
        padding-left: 10px;
        padding-right: 10px;
    `,
    headerImage: css`
        width: 100%;
        height: 250px;
    `,
    footer: css`
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        bottom: 8px;
    `
};

interface MaterialDetailPanelProps {
    /**
     * The active color
     */
    activeColor?: BffConfiguratorConfigurationColorItem;

    /**
     * The color material
     */
    material?: BffConfiguratorConfigurationColorMaterial;

    /**
     * The available colors for the material
     */
    colorsForMaterial: BffConfiguratorConfigurationColorItem[];

    /**
     * Change the selected color so the background image can be updated
     * @param color BffConfiguratorConfigurationColorItem
     * @returns
     */
    onChangeActiveColor: (color: BffConfiguratorConfigurationColorItem) => void;
    /**
     *
     * @returns
     */
    onClose: () => void;
    /**
     * Apply the color to the configuration
     * @param color BffConfiguratorConfigurationColorItem
     * @returns
     */
    onApplyColor: (color: BffConfiguratorConfigurationColorItem) => void;

    /**
     * The applied color ID of the Model
     */
    colorIsSelected: (color: BffConfiguratorConfigurationColorItem) => boolean;
}

export const MaterialDetailPanel = ({
    activeColor,
    colorsForMaterial,
    material,
    colorIsSelected,
    onClose,
    onApplyColor,
    onChangeActiveColor
}: MaterialDetailPanelProps) => {
    const panelRef = useRef<HTMLDivElement>(null);

    const { md } = useMedia();

    // Lingui hook used to track translations
    const { _ } = useLingui();

    // Color is held in state, so we can update the UI when the user selects a new color
    const [showMoreAttributes, setShowMoreAttributes] = useState(false);

    if (!activeColor || !material) {
        return null;
    }

    const handleColorChange = (color: BffConfiguratorConfigurationColorItem) => {
        onChangeActiveColor && onChangeActiveColor(color);

        panelRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleFactSheetDownload = () => {
        material?.factsheetUrl && window.open(material.factsheetUrl, '_blank');
    };

    /**
     * @todo: Implement summary section in the API
    const _renderSummary = () => {
        const items = color.features?.map((item, index) => {
            // @todo: Add translations
            const title = item;
            const description = item;
            const icon = icons[item as keyof typeof icons];

            return (
                <div key={index}>
                    <div className={styles.detail}>
                        <div>{icon}</div>
                        <div className={styles.detailText}>
                            <div className={styles.detailTextTitle}>{title}</div>
                            <div className={styles.detailTextDescription}>{description}</div>
                        </div>
                    </div>
                    <Separator />
                </div>
            );
        });

        return (
            <div className={cx(styles.section, styles.sectionPadded)}>
                <div className={styles.sectionTitle}>
                    <Trans>Summary</Trans>
                </div>
                <Separator />
                {items}
            </div>
        );
    };
     */

    const renderDetails = () => {
        // @todo: fix types
        const attributes = material.attributes?.map((item: any, index) => {
            const { key, __typename } = item;

            const title = _(translations[key as keyof typeof translations]);

            let description = '';

            // If it's weight or width, we want to show the value directly from the API
            if (key === AttributeKey.Weight || key === AttributeKey.Width) {
                description = item.textValue;
            } else {
                switch (__typename) {
                    case AttributeType.Boolean:
                        description = _(translations[`${key}_${item.booleanValue}` as keyof typeof translations]);
                        break;
                    case AttributeType.Text:
                        description = _(translations[`${key}_${item.textValue}` as keyof typeof translations]);
                        break;
                    case AttributeType.Meter:
                        // If it's naturalness, we want to show the value directly from the API
                        description =
                            key === AttributeKey.Naturalness
                                ? `${item.rawValue}%`
                                : _(translations[`${key}_${item.meterValue}` as keyof typeof translations]);

                        break;
                    case AttributeType.Number:
                        description = _(translations[`${key}_${item.numberValue}` as keyof typeof translations]);
                        break;
                    case AttributeType.Array:
                        description = item?.values
                            ?.map(
                                (value: BffConfiguratorConfigurationItemAttributeText) =>
                                    _(translations[`${key}_${value.key}` as keyof typeof translations]) ||
                                    `${key}_${value.key}`
                            )
                            .join(', ');

                        break;
                }
            }

            const iconKey: keyof typeof icons = key === 'place_of_origin' ? `${key}_${item.textValue}` : key;
            const icon = icons[iconKey];

            // Show max 9 items in collapsed mode
            if (index > 8 && !showMoreAttributes) return null;

            return (
                <div key={key}>
                    <div className={styles.detail}>
                        <div>{icon}</div>
                        <div className={styles.detailText}>
                            <div className={styles.detailTextTitle}>{title}</div>
                            <div className={styles.detailTextDescription}>{description}</div>
                        </div>
                    </div>
                    <Separator />
                </div>
            );
        });

        return (
            <div className={cx(styles.section, styles.sectionPadded)}>
                <div className={styles.sectionTitle}>
                    <Trans>Details</Trans>
                </div>

                <p className={styles.sectionDescription}>{material.text}</p>

                <Separator />

                {attributes}

                <div className={styles.sectionButton}>
                    <Button rounded variant="secondary" onPress={() => setShowMoreAttributes(!showMoreAttributes)}>
                        {showMoreAttributes ? <Trans>Show less</Trans> : <Trans>Show more</Trans>}
                        <svg
                            style={{
                                transform: showMoreAttributes ? 'rotate(180deg)' : 'rotate(0deg)'
                            }}
                            width="9"
                            height="5"
                            viewBox="0 0 9 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path id="Vector 2122" d="M8 0.5L4.5 3.5L1 0.499999" stroke="#333333" />
                        </svg>
                    </Button>
                </div>
            </div>
        );
    };

    const renderColors = () => {
        const items = colorsForMaterial?.map((item) => {
            const isSelected = item.id === activeColor.id;
            return (
                <ButtonTile.Item key={item.id}>
                    <ButtonTile.Surface
                        onClick={() => handleColorChange(item)}
                        direction="column"
                        selected={isSelected}
                    >
                        {item.listingImage && (
                            <ButtonTile.Image
                                direction="column"
                                size={['100%', '100%']}
                                image={{
                                    src: item.listingImage?.src,
                                    width: 108,
                                    height: 86
                                }}
                            />
                        )}
                        <div className={cx(styles.colorGridItemTitle, isSelected && styles.colorGridItemTitleSelected)}>
                            <MarqueeText active={false} text={item.title} />
                        </div>
                    </ButtonTile.Surface>
                </ButtonTile.Item>
            );
        });

        return (
            <div className={cx(styles.section, styles.sectionPadded)}>
                <div className={styles.sectionTitle}>
                    Colors
                    <span className={styles.sectionTitleSeparator} />
                    {colorsForMaterial?.length}
                </div>

                <Separator />

                <div className={styles.colorGrid}>{items}</div>

                <Button variant="secondary" onPress={handleFactSheetDownload}>
                    <Trans>Download factsheet</Trans>
                </Button>
            </div>
        );
    };

    const renderApplyFabric = () => {
        const isSelected = colorIsSelected(activeColor);
        if (!isSelected) {
            return (
                <Button rounded variant="secondary" onPress={() => onApplyColor(activeColor)}>
                    <Trans>Apply fabric</Trans>
                </Button>
            );
        }

        return null;
    };

    const renderOrderSamples = () => {
        if (!activeColor.orderable) {
            return null;
        }

        return (
            <>
                <div className={styles.section}>
                    <Panel.OrderSamples />
                </div>

                <Panel.Sticky position="footer">
                    <div className={styles.footer}>
                        <Button rounded variant="coral">
                            <Trans>Order sample</Trans>
                        </Button>
                    </div>
                </Panel.Sticky>
            </>
        );
    };

    const header = md ? null : (
        <img src={activeColor?.listingImage?.src} alt={activeColor?.title} className={styles.headerImage} />
    );

    return (
        <Panel.Surface rounded={md}>
            <Panel.Navigation kind="close" onPress={onClose} />
            {header}
            <Panel.ScrollArea ref={panelRef} className={styles.container}>
                <Panel.Title>
                    <div className={styles.title}>
                        <Trans>
                            {material?.name} in {activeColor?.title}
                        </Trans>
                        {renderApplyFabric()}
                    </div>
                </Panel.Title>

                {renderDetails()}
                {renderColors()}
                {renderOrderSamples()}
            </Panel.ScrollArea>
        </Panel.Surface>
    );
};
