import { useMedia } from '@common/hooks/use-media';
import {
    Close,
    ResetViewDesktop,
    Ruler,
    ShareDesktop,
    Undo,
    ViewInRoomDesktop,
    ZoomInDesktop,
    ZoomOutDesktop
} from '@common/icons';
import { Spinner } from '@common/primitives/spinner/spinner';
import { ToastProvider } from '@common/primitives/toast';
import { colors } from '@common/styles/colors';
import { ButtonIcon } from '@components/configurator/components/button-icon';
import { useViewer } from '@components/configurator/hooks/use-viewer';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import { useContext, useEffect, useState } from 'react';
import {
    Button as DefaultButton,
    Toolbar as DefaultToolbar,
    Dialog,
    DialogTrigger,
    Popover
} from 'react-aria-components';

import { ViewerContext } from '../viewer/viewer';

const styles = {
    toolbar: css`
        display: inline-flex;
        justify-content: space-between;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        background: #fff;
        color: #101010;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        border-radius: 90px;
        opacity: 0;
        transition: opacity 0.2s;

        &:hover,
        &:has(*[data-focus-visible]) {
            opacity: 1;

            span {
                opacity: 1;
                max-width: 7rem;
            }
        }

        * {
            line-height: 1;
        }
    `,
    toolbarForceVisible: css`
        opacity: 1 !important;
    `,
    toolbarButton: css`
        all: unset;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        box-sizing: border-box;

        &:disabled {
            cursor: default;
            opacity: 0.5;
        }

        &[data-focus-visible] {
            outline-offset: none;
            outline: 1.5px solid ${colors.coral2};
            overflow: visible;
            border-radius: 3px;
        }
    `,
    toolbarButtonSpinner: css`
        width: 26.5px;
        height: 26.5px;
        padding: 5px;

        span {
            opacity: 1 !important;
            max-width: 7rem !important;
        }
    `,
    toolbarButtonZoomOut: css`
        margin-left: 8px;
    `,
    toolbarDialog: css`
        width: 184px;
        padding: 32px 32px 0;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0 0 0 / 10%);

        img {
            width: 123px;
            height: 123px;
        }
    `,
    toolbarDialogText: css`
        color: ${colors.black};
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 19.5px */
        margin-top: 16px;
        padding-bottom: 28px;
    `,
    toolbarSection: css`
        padding: 0 8px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;
        overflow: hidden;

        span {
            max-width: 0;
            opacity: 0;
            transition:
                max-width 1s,
                opacity 1s,
                padding 1s;
            display: inline-block;
            vertical-align: top;
            white-space: nowrap;
        }

        &:only-child {
            min-width: 44px;
        }

        &:not(:only-child):first-child {
            padding-left: 16px;
        }

        &:not(:only-child):last-child {
            padding-right: 16px;
        }
    `
};

interface ToolbarProps {
    className?: string;
    onUndo?: () => void;
    onShare?: () => Promise<void | string>;
    hasUndo?: boolean;
    hasResetView?: boolean;
    hasDimensions?: boolean;
    forceVisible?: boolean;
}

export const ToolbarDesktop = ({
    className,
    onUndo,
    onShare,
    hasUndo,
    hasDimensions,
    hasResetView,
    forceVisible
}: ToolbarProps) => {
    const [zoomLevel, setZoomLevel] = useState<number>(10);
    const { viewer, startAr, checkAr, arQrCode, resetCamera, setZoomProportion, toggleRulers } = useViewer();
    const [arPopoverVisible, setArPopoverVisible] = useState(false);
    const [hasAr, setHasAr] = useState<boolean>(true);

    const [shareProcessing, setShareProcessing] = useState(false);
    const { isHovered: viewerHovered } = useContext(ViewerContext);

    const { md } = useMedia();

    useEffect(() => {
        const check = async () => {
            const ar = await checkAr();
            setHasAr(ar);
        };
        check();
    }, []);

    const getZoomLevel = async () => {
        const c = await viewer?.getCamera();
        return c.zoomProportion * 100;
    };

    useEffect(() => {
        const run = async () => {
            const z = await getZoomLevel();
            setZoomLevel(z);
        };
        run();
    }, [viewer]);

    const postZoomLevel = async (level: number) => {
        setZoomLevel(level);
        const value = level / 100;
        try {
            setZoomProportion(value);
        } catch (error) {
            console.error(error);
        }
    };

    const onZoomIn = async () => {
        const z = await getZoomLevel();
        // let newLevel = 100 - (100 - z) / 2;
        let newLevel = z + (100 / 3 + 1);
        newLevel = newLevel <= 100 ? newLevel : 100;
        postZoomLevel(newLevel);
    };

    const onZoomOut = async () => {
        const z = await getZoomLevel();
        // let newLevel = 100 - 2 * (100 - z);
        let newLevel = z - 100 / 3 - 1;
        newLevel = newLevel >= 0 ? newLevel : 0;
        postZoomLevel(newLevel);
    };

    const onStartAr = async () => {
        startAr();
        setArPopoverVisible(true);
    };

    const onToggleRuler = async () => {
        await toggleRulers({ size: 18 });
    };

    const onResetView = () => {
        resetCamera();
    };
    if (!md) return null;

    if (arPopoverVisible && hasAr && arQrCode) {
        return (
            <div className={cx('viewer-toolbar-desktop', styles.toolbar, styles.toolbarForceVisible, className)}>
                <DialogTrigger>
                    <ButtonIcon aria-label="Close" icon={<Close />} />
                    <Popover
                        placement="top"
                        defaultOpen={arPopoverVisible}
                        offset={10}
                        onOpenChange={(isOpen: boolean) => setArPopoverVisible(isOpen)}
                    >
                        <Dialog className={cx(styles.toolbarDialog)}>
                            <img src={arQrCode} alt="AR QR code" />
                            <p className={cx(styles.toolbarDialogText)}>
                                <Trans>Scan this QR code with your phone.</Trans>
                            </p>
                        </Dialog>
                    </Popover>
                </DialogTrigger>
            </div>
        );
    }

    return (
        <ToastProvider>
            {(toastState) => (
                <DefaultToolbar
                    className={cx(
                        'viewer-toolbar-desktop',
                        styles.toolbar,
                        (forceVisible || viewerHovered) && styles.toolbarForceVisible,
                        className
                    )}
                >
                    {onUndo && (
                        <div className={cx('viewer-toolbar-desktop-section', styles.toolbarSection)}>
                            <DefaultButton
                                className={cx('viewer-toolbar-desktop-button', styles.toolbarButton)}
                                isDisabled={!hasUndo}
                                onPress={onUndo}
                            >
                                <Undo />
                                <span>
                                    <Trans>Undo</Trans>
                                </span>
                            </DefaultButton>
                        </div>
                    )}
                    {hasResetView && (
                        <div className={cx('viewer-toolbar-desktop-section', styles.toolbarSection)}>
                            <DefaultButton
                                className={cx('viewer-toolbar-desktop-button', styles.toolbarButton)}
                                onPress={onResetView}
                            >
                                <ResetViewDesktop />
                                <span>
                                    <Trans>Reset View</Trans>
                                </span>
                            </DefaultButton>
                        </div>
                    )}
                    <div className={cx('viewer-toolbar-desktop-section', styles.toolbarSection)}>
                        <DefaultButton
                            aria-label={t`Zoom in`}
                            className={cx('viewer-toolbar-desktop-button', styles.toolbarButton)}
                            onPress={onZoomIn}
                            isDisabled={zoomLevel >= 100}
                        >
                            <ZoomInDesktop />
                        </DefaultButton>
                        <span>
                            <DefaultButton
                                isDisabled={zoomLevel == 0}
                                aria-label={t`Zoom out`}
                                className={cx(
                                    'viewer-toolbar-desktop-button',
                                    styles.toolbarButton,
                                    styles.toolbarButtonZoomOut
                                )}
                                onPress={onZoomOut}
                            >
                                <ZoomOutDesktop />
                            </DefaultButton>
                        </span>
                    </div>
                    {hasDimensions && (
                        <div className={cx('viewer-toolbar-desktop-section', styles.toolbarSection)}>
                            <DefaultButton
                                className={cx('viewer-toolbar-desktop-button', styles.toolbarButton)}
                                onPress={onToggleRuler}
                            >
                                <Ruler />
                                <span>
                                    <Trans>Dimensions</Trans>
                                </span>
                            </DefaultButton>
                        </div>
                    )}
                    {hasAr && (
                        <div className={cx('viewer-toolbar-desktop-section', styles.toolbarSection)}>
                            <DefaultButton
                                className={cx('viewer-toolbar-desktop-button', styles.toolbarButton)}
                                onPress={onStartAr}
                            >
                                <ViewInRoomDesktop />
                                <span>
                                    <Trans>View in Room</Trans>
                                </span>
                            </DefaultButton>
                        </div>
                    )}
                    <div className={cx('viewer-toolbar-desktop-section', styles.toolbarSection)}>
                        <DefaultButton
                            className={cx('viewer-toolbar-desktop-button', styles.toolbarButton)}
                            onPress={async () => {
                                if (onShare) {
                                    setShareProcessing(true);
                                    await onShare();
                                    setShareProcessing(false);
                                    toastState.add(<Trans>Copied Share Link to Clipboard</Trans>, { timeout: 4500 });
                                }
                            }}
                        >
                            {shareProcessing && <Spinner className={cx(styles.toolbarButtonSpinner)} />}
                            {!shareProcessing && <ShareDesktop />}
                            <span>
                                <Trans>Share</Trans>
                            </span>
                        </DefaultButton>
                    </div>
                </DefaultToolbar>
            )}
        </ToastProvider>
    );
};
