import { useMedia } from '@common/hooks/use-media';
import { Tab, Tabs } from '@common/primitives/tabs';
import { media } from '@common/styles/media';
import { Layer, Panel, ShareMenu } from '@components/configurator';
import { ConfiguratorTab, useConfigurator } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { Trans } from '@lingui/macro';

const styles = {
    tabHeader: css`
        display: flex;
        align-items: flex-start;
    `,
    tabHeaderButton: css`
        margin-top: 18px;
        display: flex;
    `,
    tabPanelSaved: css`
        .panel-tab-panel-content {
            justify-content: flex-start;
        }
    `,
    tabList: css`
        display: flex;
        flex-direction: column;
        width: 100%;

        .tab-list {
            ${media.md} {
                align-items: flex-end;
                width: 352px;
                max-width: 100%;
            }
        }
    `
};

export const LayerOverview = () => {
    const onSetTab = useConfigurator((state) => state.onSetTab);
    const selectedTab = useConfigurator((state) => state.ui?.tab);
    const saved = useConfigurator((state) => state.saved);
    const { md } = useMedia();

    const updateRemoteConfiguration = useConfigurator((state) => state.updateRemoteConfiguration);

    const shareMenu = selectedTab === 'saved' && saved.length > 0 && (
        <div className={styles.tabHeaderButton}>
            <ShareMenu />
        </div>
    );

    const tabHeader = (
        <div className={styles.tabHeader}>
            {md && shareMenu}
            <div className={styles.tabList}>
                <Panel.TabList aria-label="Menu">
                    <Tab id="configure">
                        <Trans>Configure</Trans>
                    </Tab>
                    <Tab id="recommended">
                        <Trans>Recommended</Trans>
                    </Tab>
                    <Tab id="saved">
                        <Trans>Saved</Trans>
                    </Tab>
                </Panel.TabList>
            </div>
        </div>
    );

    const tabs = (
        <Tabs selectedKey={selectedTab} onSelectionChange={(e) => onSetTab(e.toString() as ConfiguratorTab)}>
            {tabHeader}

            <Panel.TabPanel id="configure">
                <Layer.OverviewConfigure />
            </Panel.TabPanel>

            <Panel.TabPanel id="recommended">
                <Layer.OverviewRecommended />
            </Panel.TabPanel>

            <Panel.TabPanel id="saved" className={styles.tabPanelSaved}>
                <Layer.OverviewShoppingList
                    header={shareMenu}
                    footer={tabHeader}
                    onEdit={(sku: string) => {
                        updateRemoteConfiguration(sku);
                        onSetTab('configure');
                    }}
                    onUpdate={(sku: string) => {
                        updateRemoteConfiguration(sku);
                    }}
                />
            </Panel.TabPanel>
        </Tabs>
    );

    if (md) {
        return <Panel.ScrollArea>{tabs}</Panel.ScrollArea>;
    }

    return tabs;
};
