import { useMedia } from '@common/hooks/use-media';
import { Skeleton } from '@common/primitives/skeleton';
import { media } from '@common/styles/media';
import { BaseWidget } from '@components/base-widget';
import { Header, type WidgetConfigurator } from '@components/configurator';
import { useConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { styles as buttonStyles } from '@primitives/button/button';
import { useEffect } from 'react';
import { Button as DefaultButton, Modal, ModalOverlay } from 'react-aria-components';

import { ConfiguratorStandard } from './configurator-standard';

const styles = {
    widget: css`
        padding-top: 118px;
        padding-bottom: 118px;
        overflow: hidden;

        > h2 {
            font-size: 2.942rem;
            line-height: 3.8rem;
            font-weight: 100;
            text-align: center;
            padding-bottom: 52px;
        }
    `,
    widgetConfigurator: css`
        width: 100dvw;
        min-height: 612px;
        position: relative;
        display: flex;
        flex-direction: column;

        ${media.md} {
            background-color: #f5f5f5;
            height: 100vh;
            max-height: 800px;
        }
    `,
    widgetImage: css`
        width: 100%;
        height: 100%;
        object-fit: contain;
    `,
    widgetImageContainer: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    widgetImageTint: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        mix-blend-mode: multiply;
    `,
    widgetText: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 22px;
        padding-bottom: 70px;
        background: #f5f5f5;
        justify-content: center;

        .header-body {
            align-self: center;
        }
    `,
    widgetButton: css`
        padding: 14px 21px;
        background-color: #333;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%;
        letter-spacing: 1.96px;
        text-transform: uppercase;

        &[data-pressed] {
            background-color: #666;
        }
    `,
    widgetModal: css`
        position: fixed;
        inset: 0;
        display: flex;
        background-color: #f5f5f5;
    `,
    widgetSkeleton: css`
        height: 612px;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        z-index: 1;
        gap: 8px;
    `,
    widgetSkeletonImage: css`
        margin-bottom: 24px;
    `
};

export function Widget(props: WidgetConfigurator) {
    const { md } = useMedia();
    const { id } = props;

    const data = useConfigurator((state) => state.data);
    const startConfiguration = useConfigurator((state) => state.startConfiguration);
    const isModalOpen = useConfigurator((state) => state.ui.isModalOpen);
    const setIsModalOpen = useConfigurator((state) => state.setIsModalOpen);

    useEffect(() => {
        startConfiguration(props.content.product.id, props.content.recommendations);
    }, []);

    const title = (
        <h2>
            <Trans>Configure</Trans>
        </h2>
    );

    const skeleton = (
        <div className={styles.widgetSkeleton}>
            {/** Skeleton image */}
            <Skeleton width={120} height={120} className={styles.widgetSkeletonImage} />

            {/** Skeleton text line 1 */}
            <Skeleton width="65%" height={20} />

            {/** Skeleton text line 2 */}
            <Skeleton width="20%" height={20} />

            {/** Skeleton button */}
            <Skeleton width={140} height={45} />
        </div>
    );

    // If it's desktop, render the configurator inline
    if (md) {
        return (
            <BaseWidget key={id}>
                <div className={styles.widget}>
                    {title}
                    <div className={styles.widgetConfigurator}>
                        <ConfiguratorStandard />
                    </div>
                </div>
            </BaseWidget>
        );
    }

    // If it's mobile render a PDP that triggers a modal
    return (
        <BaseWidget key={id}>
            <div className={styles.widget}>
                {title}
                <div className={styles.widgetConfigurator}>
                    {data ? (
                        <>
                            <div className={styles.widgetImageContainer}>
                                <img className={styles.widgetImage} src={data?.image?.src} alt={''} />
                                <div className={styles.widgetImageTint} />
                            </div>
                            <div className={styles.widgetText}>
                                <Header name={data?.name} price={data?.price} shipping={data?.delivery?.deliveryTime} />
                                <DefaultButton
                                    className={cx(buttonStyles.reset, styles.widgetButton)}
                                    onPress={() => setIsModalOpen(true)}
                                >
                                    <Trans>Configure</Trans>
                                </DefaultButton>
                            </div>
                        </>
                    ) : (
                        skeleton
                    )}
                </div>
            </div>
            <ModalOverlay isOpen={isModalOpen}>
                <Modal className={styles.widgetModal}>
                    <ConfiguratorStandard />
                </Modal>
            </ModalOverlay>
        </BaseWidget>
    );
}
