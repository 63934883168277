import { Close, MenuHorizontal } from '@common/icons';
import { ButtonIcon, Pill as Filter } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { useId, useState } from 'react';
import { Label, LabelProps, TagGroup, TagGroupProps, TagList } from 'react-aria-components';

export const styles = {
    container: css`
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
    `,
    containerExpanded: css`
        flex-direction: column;
    `,
    filterList: css`
        display: flex;
        gap: 6px;
        padding: 16px 0;
    `,
    filterListExpanded: css`
        flex-direction: column;
        align-items: center;
    `,
    filterGrid: css`
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(58px * 4 + 6px * 3);
        gap: 6px;
        padding: 16px 0;

        .filter-item {
            width: 58px;
            height: 58px;
        }
    `,
    filterGroup: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    buttonContainer: css`
        position: relative;

        button {
            padding: 8px;
        }
    `,

    buttonContainerExpanded: css`
        margin-bottom: 1px;
    `,
    filterLabel: css`
        color: ${colors.black};
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 139%;
        text-align: center;
    `
};

const FilterLabel = (props: LabelProps) => {
    return <Label {...props} className={cx(styles.filterLabel)} />;
};

interface LayerConfigureColorFilterGroupProps extends Omit<TagGroupProps, 'children'> {
    label?: string;
    items: {
        id: string;
        image?: React.ReactNode;
        label: string;
    }[];
}

export const LayerConfigureColorFilterGroup = ({ label, items, ...props }: LayerConfigureColorFilterGroupProps) => {
    const [expanded, setExpanded] = useState(false);

    const getSliceLength = () => {
        const [firstItem, secondItem] = items;

        if (firstItem?.label?.length > 20 || secondItem?.label?.length > 20) {
            return 1;
        }

        return 2;
    };

    const renderItems = expanded ? items : items.slice(0, getSliceLength());

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const id = useId();

    return (
        <TagGroup {...props} className={styles.filterGroup} id={id}>
            <FilterLabel>{label}</FilterLabel>
            <div className={cx(styles.container, expanded && styles.containerExpanded)}>
                <TagList className={cx(styles.filterList, expanded && styles.filterListExpanded)}>
                    {renderItems.map((item) => (
                        <Filter key={item.id} {...item} />
                    ))}
                </TagList>
                <div className={cx(styles.buttonContainer, expanded && styles.buttonContainerExpanded)}>
                    <ButtonIcon
                        aria-controls={id}
                        aria-expanded={expanded}
                        aria-label={expanded ? t`Show less` : t`Show more`}
                        onPress={handleExpand}
                        icon={expanded ? <Close /> : <MenuHorizontal />}
                    />
                </div>
            </div>
        </TagGroup>
    );
};

export const LayerConfigureColorFilterGroupColour = ({
    label,
    items,
    ...props
}: LayerConfigureColorFilterGroupProps) => {
    return (
        <TagGroup {...props} className={styles.filterGroup}>
            <FilterLabel>{label}</FilterLabel>
            <TagList className={styles.filterGrid}>
                {items.map((item) => (
                    <Filter className={'filter-item'} key={item.id} {...item} />
                ))}
            </TagList>
        </TagGroup>
    );
};
