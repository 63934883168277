import { BffConfiguratorConfiguration, BffConfiguratorConfigurationColor } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Save } from '@common/icons';
import { Button } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { AddToCartButton, Layer, Panel } from '@components/configurator';
import { ToolbarMobile } from '@components/configurator/components/toolbar';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { AnimatePresence, motion } from 'framer-motion';
import { Modal, ModalOverlay } from 'react-aria-components';

const styles = {
    configuratorPanels: css`
        position: relative;
        margin: 9px 3px 3px;
        width: calc(100dvw - 6px);

        ${media.md} {
            align-self: flex-end;
            margin: 0;
            position: absolute;
            display: flex;
            height: 100%;
            width: auto;
            right: 0;
        }
    `,
    configuratorPanel: css`
        height: 100dvh;
        border-radius: 0;

        ${media.md} {
            margin: 12px;
            height: auto;
            display: flex;
            position: relative;

            > div {
                overflow: hidden;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 0 10px 0 rgba(0 0 0 / 10%);
            }
        }
    `,
    configuratorLayer: css`
        ${media.md} {
            display: flex;
            height: 100%;
            overflow: hidden;
        }
    `,
    configuratorModal: css`
        position: fixed;
        inset: 0;
        height: 100dvh;
        width: 100dvw;
    `
};

export const ConfiguratorStandardPanels = () => {
    const layerId = useConfigurator((state) => state.ui.id);
    const filterLayerId = useConfigurator((state) => state.ui.filterLayerId);
    const tab = useConfigurator((state) => state.ui.tab);
    const filters = useConfigurator((state) => state.ui.filters);

    const saved = useConfigurator((state) => state.saved);
    const data = useConfigurator((state) => state.data);
    const onClearFilter = useConfigurator((state) => state.onClearFilter);
    const onSetFilter = useConfigurator((state) => state.onSetFilter);
    const onToggleFilterLayerById = useConfigurator((state) => state.onToggleFilterLayerById);
    const getFilteredColors = useConfigurator((state) => state.getFilteredColors);

    const visible = useViewer((state) => state.loaded);

    const configuration = data?.configurations?.find((config) => config.id === layerId) as BffConfiguratorConfiguration;

    const filteredColors = getFilteredColors(configuration?.id);

    const { md } = useMedia();

    const renderLayer = () => {
        const variants = {
            initial: { x: '100%' },
            enter: { x: '0%' },
            exit: { x: '-100%' }
        };

        const motionProps = {
            key: layerId,
            transition: { duration: 0.35 },
            variants,
            initial: 'initial',
            animate: 'enter',
            exit: 'initial',
            className: styles.configuratorLayer
        };

        if (!layerId) {
            return (
                <motion.div {...motionProps} initial="exit" animate="enter" exit="exit">
                    <Layer.Overview />
                </motion.div>
            );
        }

        return (
            <motion.div {...motionProps}>
                <Layer.Configure id={layerId} />
            </motion.div>
        );
    };

    const renderFilterLayer = () => {
        const filterData = data?.configurations?.find(
            (config) => config.id === filterLayerId
        ) as BffConfiguratorConfigurationColor;

        if (!filterData) {
            return null;
        }

        const panel = (
            <motion.div
                key={'secondary'}
                className={styles.configuratorLayer}
                initial={{ x: '50%', opacity: 0 }}
                animate={{ x: '0%', opacity: 1 }}
                exit={{ x: '50%', opacity: 0 }}
                transition={{ duration: 0.25 }}
            >
                <div className={styles.configuratorPanel}>
                    <Panel.Surface rounded={md}>
                        <Layer.ConfigureColorFilter
                            resultCount={filteredColors.length}
                            activeFilters={filters}
                            onClearFilter={onClearFilter}
                            onSetFilter={onSetFilter}
                            onClose={() => onToggleFilterLayerById(filterLayerId as string)}
                            colors={filterData.colors || []}
                            materials={filterData.materials || []}
                        />
                    </Panel.Surface>
                </div>
            </motion.div>
        );

        if (md) return <AnimatePresence>{filterLayerId && panel}</AnimatePresence>;

        return (
            <ModalOverlay className={styles.configuratorModal}>
                <Modal>{panel}</Modal>
            </ModalOverlay>
        );
    };

    if (!data) {
        return null;
    }

    const footer =
        tab === 'saved' ? null : (
            <Panel.Footer>
                <Button aria-label="Save">
                    <Save stroke={colors.coral2} />
                </Button>
                <AddToCartButton sku={data?.sku} />
            </Panel.Footer>
        );

    if (md)
        return (
            <div className={styles.configuratorPanels}>
                {renderFilterLayer()}

                <div className={cx(styles.configuratorPanel)}>
                    <Panel.Surface size={tab === 'saved' && saved.length > 0 ? 'lg' : 'base'}>
                        <AnimatePresence mode="popLayout" initial={false}>
                            {renderLayer()}
                        </AnimatePresence>

                        {md && footer}
                    </Panel.Surface>
                </div>
            </div>
        );

    return (
        <>
            <div className={styles.configuratorPanels}>
                {visible && <ToolbarMobile />}

                <AnimatePresence>{filterLayerId && renderFilterLayer()}</AnimatePresence>

                <Panel.Surface>
                    <AnimatePresence mode="popLayout" initial={false}>
                        {renderLayer()}
                    </AnimatePresence>
                </Panel.Surface>
            </div>
        </>
    );
};
