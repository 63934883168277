import {
    BffConfiguratorConfigurationColor,
    BffConfiguratorConfigurationColorItem,
    BffConfiguratorConfigurationColorMaterial
} from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Button } from '@common/primitives/button';
import { media } from '@common/styles/media';
import { Panel } from '@components/configurator/components/panel';
import { MODULAR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useModularConfigurator } from '@components/configurator/hooks';
import { MaterialComparisonTable } from '@components/material-comparison/components/table';
import { css } from '@linaria/core';
import { plural, t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { Modal, ModalOverlay, Selection } from 'react-aria-components';

import { LayerConfigureColorOptionsCompare } from './layer-configure-color-options-compare';

const styles = {
    stickyFooter: css`
        position: fixed;
        left: 0;
    `,
    overlay: css`
        position: fixed;
        inset: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        background-size: cover;
        background-position: center;
        z-index: ${MODULAR_MOBILE_MODAL_Z_INDEX + 2};
    `,
    modal: css`
        height: 100dvh;
        width: 100dvw;

        ${media.md} {
            width: 938px;
            max-width: 100dvw;
            margin: 12px;
            height: calc(100dvh - 24px);
        }
    `,
    scrollArea: css`
        padding: 0;
    `
};

interface LayerConfigureColorCompareProps {
    configuration: BffConfiguratorConfigurationColor;
    filteredColors: BffConfiguratorConfigurationColorItem[];
    onReset: () => void;
}

export const LayerConfigureColorCompare = ({
    configuration,
    filteredColors,
    onReset
}: LayerConfigureColorCompareProps) => {
    const { md } = useMedia();

    const { activeData } = useModularConfigurator();
    const isCompareOpen = useModularConfigurator((state) => state.ui.isCompareOpen);
    const setIsCompareModalOpen = useModularConfigurator((state) => state.setIsCompareOpen);

    // react-aria-components Selection set
    const [selectedKeys, setSelectedKeys] = useState<Selection>();
    const selectedCompareColors = filteredColors.filter((color) => {
        return selectedKeys && selectedKeys !== 'all' && [...selectedKeys].includes(color.id);
    });

    const table = (
        <Panel.ScrollArea className={styles.scrollArea}>
            <Panel.Title title={t`Compare Fabrics`} />
            <Panel.Navigation kind="close" onPress={() => setIsCompareModalOpen(false)} />
            <MaterialComparisonTable
                currencyCode={activeData?.price?.currencyCode || 'EUR'}
                selectedColors={selectedCompareColors}
                materials={configuration.materials as BffConfiguratorConfigurationColorMaterial[]}
                onRemoveColor={(key) =>
                    setSelectedKeys((keys) => {
                        return keys && keys !== 'all' ? new Set([...keys].filter((k) => k !== key)) : keys;
                    })
                }
                onClose={() => setIsCompareModalOpen(false)}
            />
        </Panel.ScrollArea>
    );

    if (isCompareOpen && md) {
        return table;
    }

    const body = (
        <>
            <LayerConfigureColorOptionsCompare
                filteredColors={filteredColors}
                configuration={configuration}
                onSelectionChange={setSelectedKeys}
                selectedKeys={selectedKeys}
            />
            {selectedCompareColors.length > 0 && (
                <Panel.Sticky position="footer" className={styles.stickyFooter}>
                    {selectedCompareColors.length === 1 ? (
                        <Panel.Hint>
                            <Trans>Select more fabrics</Trans>
                        </Panel.Hint>
                    ) : (
                        <Button variant={'coral'} rounded onPress={() => setIsCompareModalOpen(true)}>
                            {plural(selectedCompareColors?.length || 0, {
                                other: 'Compare # fabrics'
                            })}
                        </Button>
                    )}
                </Panel.Sticky>
            )}
        </>
    );

    if (md) {
        return (
            <>
                <Panel.Navigation kind="close" onPress={onReset} />
                <Panel.ScrollArea>
                    <Panel.Title>
                        <Trans>Select Fabrics</Trans>
                    </Panel.Title>
                    {body}
                </Panel.ScrollArea>
            </>
        );
    }

    return (
        <>
            <Panel.Navigation appearance="mobile" kind="back" title={t`Select Fabrics`} onPress={onReset} />
            <Panel.ScrollArea direction="horizontal">{body}</Panel.ScrollArea>

            <ModalOverlay isOpen={isCompareOpen} className={styles.overlay}>
                <Modal className={styles.modal} isOpen={isCompareOpen}>
                    <Panel.Surface>{table}</Panel.Surface>
                </Modal>
            </ModalOverlay>
        </>
    );
};
