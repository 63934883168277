import { useEffect, useState } from 'react';

interface UseElementLeavesViewportParams {
    ref: React.RefObject<HTMLElement>;
    offset?: number;
    delay?: number;
    rootElementSelector: string;
}

export function useElementLeavesViewport(params: UseElementLeavesViewportParams, deps: any[] = []) {
    const { ref, offset = 0, delay = 0, rootElementSelector } = params;
    const [outOfViewPort, setOutOfViewPort] = useState(false);

    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        let timeoutId: NodeJS.Timeout | null = null;
        const root = (document.querySelectorAll(rootElementSelector) || [])[0];
        if (!root) {
            return;
        }
        const startObserving = () => {
            observer = new IntersectionObserver(
                ([entry]) => {
                    setOutOfViewPort(entry.isIntersecting && entry.intersectionRatio < 1);
                },
                {
                    root,
                    threshold: [0, 1],
                    rootMargin: `${offset}px 0px`
                }
            );

            if (ref.current) {
                observer.observe(ref.current);
            }
        };

        const stopObserving = () => {
            if (observer && ref.current) {
                observer.unobserve(ref.current);
            }
        };

        if (delay > 0) {
            timeoutId = setTimeout(() => {
                startObserving();
            }, delay);
        } else {
            startObserving();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            stopObserving();
        };
    }, deps); // Include delay in the dependency array

    return outOfViewPort;
}
