import { media } from '@common/styles/media';
import { css } from '@linaria/core';

import { ConfiguratorStandardHeader } from './configurator-standard-header';
import { ConfiguratorStandardPanels } from './configurator-standard-panels';
import { ConfiguratorStandardViewer } from './configurator-standard-viewer';

const styles = {
    container: css`
        width: 100dvw;
        height: 100dvh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        ${media.md} {
            width: 100%;
            max-width: 1600px;
            margin: auto;
            overflow: visible;
            flex: 1;
        }
    `
};

export const ConfiguratorStandard = () => {
    return (
        <div className={styles.container}>
            <ConfiguratorStandardViewer />
            <ConfiguratorStandardHeader />
            <ConfiguratorStandardPanels />
        </div>
    );
};
