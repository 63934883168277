import { client } from '@common/graphql/client';
import { getBffLanguageAndCountry, useConfig } from '@common/hooks/use-config';
import { Button } from '@common/primitives/button';
import { ButtonProps } from '@common/primitives/button/button';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useRef, useState } from 'react';

import { CartIconAnimation } from './button-add-to-cart-animation';

const styles = {
    buttonWrapper: css`
        white-space: nowrap;

        button {
            position: relative;
            overflow: hidden;
            font-size: 15px;
            transition: background-color 0.3s ease;
        }
    `,
    hidden: css`
        opacity: 0;
    `,
    column: css`
        position: absolute;
        width: 100%;
        height: 100%;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            &:nth-child(1) {
                transform: translateY(0);
            }

            &:nth-child(2) {
                transform: translateY(100%);
            }

            &:nth-child(3) {
                transform: translateY(200%);
            }
        }
    `,
    animate: css`
        animation: slide-up 1.5s ease-in-out forwards;

        @keyframes slide-up {
            0% {
                transform: translateY(0%);
            }

            25% {
                transform: translateY(-100%);
            }

            75% {
                transform: translateY(-100%);
            }

            100% {
                transform: translateY(-200%);
            }
        }
    `
};
interface AddToCartButtonProps extends Omit<ButtonProps, 'children'> {
    buttonText?: string;
    checkoutText?: string;
    className?: string;
    color?: string;
    sku: string;
}

export const AddToCartButton = (props: AddToCartButtonProps) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [coralTone, setCoralTone] = useState(false);
    const { buttonText, checkoutText, className, color, variant = 'primary', sku, ...rest } = props;
    const { country, language } = getBffLanguageAndCountry();

    const startAnimation = () => {
        setIsAnimating(true);
    };

    const animationRef = useRef(null);

    const handleAnimationEnd = () => {
        setTimeout(() => {
            setCoralTone(true);
        }, 300);

        //@todo: we should instead listen to the store and reset the animation state if anything is changed
        setTimeout(() => {
            setIsAnimating(false);
            setCoralTone(false);
        }, 4000);
    };

    const handeAddToCart = async () => {
        startAnimation();

        await client.addToCart({
            country,
            language,
            sku
        });
    };

    return (
        <div className={cx(styles.buttonWrapper, className)}>
            <Button
                variant={coralTone ? 'coral' : variant}
                onPress={
                    coralTone ? () => (window.location.href = useConfig('checkoutUrl', '/checkout')) : handeAddToCart
                }
                isDisabled={isAnimating && !coralTone}
                {...rest}
            >
                <span className={cx(isAnimating && styles.hidden)}>Add to cart</span>
                {isAnimating && (
                    <span
                        ref={animationRef}
                        onAnimationEnd={handleAnimationEnd}
                        className={cx(styles.column, styles.animate)}
                    >
                        <span>
                            <Trans>{buttonText || 'Add to cart'}</Trans>
                        </span>
                        <span
                            style={{
                                color
                            }}
                        >
                            <CartIconAnimation animationActive={true} animationDelay={0.75} />
                        </span>
                        <span>
                            <Trans>{checkoutText || 'Go to checkout'}</Trans>
                        </span>
                    </span>
                )}
            </Button>
        </div>
    );
};
