import { BffConfiguratorConfiguration, BffConfiguratorConfigurationColorItem } from '@common/graphql/sdk';
import { useConfigurator } from '@components/configurator/hooks';
import { useViewer } from '@components/configurator/hooks/use-viewer';
import { useEffect } from 'react';

import { LayerConfigureAttribute } from './layer-configure-attribute/layer-configure-attribute';
import { LayerConfigureColor } from './layer-configure-color/layer-configure-color';

const TRANSITION_TIME = 350;

export const LayerConfigure = ({ id }: { id: string }) => {
    const onCloseFilter = useConfigurator((state) => state.onCloseFilter);
    const updateRemoteConfiguration = useConfigurator((state) => state.updateRemoteConfiguration);
    const getFilteredColors = useConfigurator((state) => state.getFilteredColors);
    const onSetFilter = useConfigurator((state) => state.onSetFilter);
    const onClearFilter = useConfigurator((state) => state.onClearFilter);
    const onToggleFilterLayerById = useConfigurator((state) => state.onToggleFilterLayerById);
    const getFilterCount = useConfigurator((state) => state.getFilterCount);
    const filters = useConfigurator((state) => state.ui.filters);

    const filterCount = getFilterCount();

    const screenshots = useViewer((state) => state.screenshots);
    const viewer = useViewer((state) => state.viewer);
    const updateViewer = useViewer((state) => state.updateViewer);

    // @todo check for re-rendering
    const data = useConfigurator((state) => state.data);
    const configuration = data?.configurations?.find((config) => config.id === id) as BffConfiguratorConfiguration;

    const currentSku = data!.sku!;
    const filteredColors = getFilteredColors(configuration?.id);

    useEffect(() => {
        const screenshot = screenshots.find((screenshot) => {
            return screenshot.viewpoint?.indexOf(configuration?.viewpoint ?? '') !== -1;
        });

        if (screenshot) {
            viewer
                ?.setViewpoint({
                    name: screenshot.viewpoint,
                    transitionTime: TRANSITION_TIME
                })
                .then(() => {
                    viewer.getCamera().then((camera: { zoomProportion: number }) => {
                        const value = camera.zoomProportion > 1 ? 1 : camera.zoomProportion - 0.1;

                        viewer?.setZoomProportion({
                            value,
                            transitionTime: TRANSITION_TIME * 5
                        });
                    });
                });
        }
    }, [viewer]);

    const handleBack = () => {
        viewer?.resetCamera({ transitionTime: TRANSITION_TIME });
        onCloseFilter();
    };

    useEffect(() => {
        if (!configuration) {
            handleBack();
        }
    }, [configuration]);

    const updateColorConfiguration = async (color: BffConfiguratorConfigurationColorItem) => {
        // we do not await to trigger this at the same time
        updateViewer(color.sku);
        updateRemoteConfiguration(color.sku);
    };

    const updateConfiguration = (sku: string) => {
        updateViewer(sku);
        updateRemoteConfiguration(sku);
    };

    if (!configuration) {
        return null;
    }

    if (configuration.__typename === 'BffConfiguratorConfigurationAttributes') {
        return (
            <LayerConfigureAttribute
                currentSku={currentSku}
                configuration={configuration}
                onChangeOption={updateConfiguration}
                onClose={handleBack}
            />
        );
    }

    if (configuration.__typename === 'BffConfiguratorConfigurationColor') {
        return (
            <LayerConfigureColor
                configuration={configuration}
                filteredColors={filteredColors}
                filterCount={filterCount}
                searchText={filters?.search || ''}
                colorIsSelected={(color) => color.sku === currentSku}
                onChangeColor={updateColorConfiguration}
                onClose={handleBack}
                onSetFilter={onSetFilter}
                onClearFilter={onClearFilter}
                onToggleFilterLayerById={onToggleFilterLayerById}
            />
        );
    }

    return null;
};
