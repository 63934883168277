import { client } from '@common/graphql/client';
import {
    BffConfigurator,
    BffConfiguratorConfigurationColor,
    BffConfiguratorConfigurationColorItem,
    BffDelivery,
    BffPrice,
    BffProductImage
} from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { unmarshalSKU } from '@common/utils/sku';
import { SearchEngine } from 'clientside-search';
import en from 'clientside-search/en';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { WidgetConfiguratorContentRecommendation } from '../configurator-types';
import { ConfiguratorTab, ConfiguratorUI, FilterKey } from './types';
import { useViewer } from './use-viewer';
import { calculateFilterCount, createSearchEngineFromColors, searchForColors } from './utils';

interface BffConfiguratorSaved {
    name: string;
    sku: string;
    image: BffProductImage;
    price: BffPrice;
    delivery: BffDelivery;
    isInCart: boolean;
}

interface ConfiguratorStore {
    recommendations: WidgetConfiguratorContentRecommendation[];
    data: BffConfigurator | undefined;
    saved: BffConfiguratorSaved[];
    ui: ConfiguratorUI;
}

interface ConfiguratorActions {
    /**
     * Boostrap the configurator
     * @param sku sku
     * @returns
     */
    startConfiguration: (
        sku: string,
        recommendations?: WidgetConfiguratorContentRecommendation[]
    ) => Promise<BffConfigurator | undefined>;

    /**
     * Updates and fetches the new configuration
     * @param newSku sku
     * @returns
     */
    updateRemoteConfiguration: (newSku: string) => Promise<BffConfigurator | undefined>;

    /**
     * Clears all filters
     * @returns void
     */
    onClearFilter: () => void;
    /**
     * set's a filter on the current open filter layer
     * @param key FilterKey
     * @param filter Filter Values
     * @returns
     */
    onSetFilter: (key: FilterKey, filter: string | string[] | undefined) => void;
    /**
     * Toggles a color / materials filter layer
     * @param filterLayerId Filter layer Id
     * @returns
     */
    onToggleFilterLayerById: (filterLayerId?: string) => void;

    /**
     * Close the current filter layer, keeps the filter
     * @returns
     */
    onCloseFilter: () => void;

    /**
     * Returns the filtered colors based on the current filters and selected state
     * @returns BffConfiguratorConfigurationColorItem[]
     */
    getFilteredColors: (ofmlKey: string) => BffConfiguratorConfigurationColorItem[];
    /**
     * Retun active filter count for the selected filterId
     * @returns number
     */
    getFilterCount: () => number;

    /**
     * Sets tab in the ui
     * @param tab ConfiguratorTab
     * @returns voic
     */
    onSetTab: (tab: ConfiguratorTab) => void;

    /**
     * set's the layer by id
     * mabye set the filterLayerId if it's a colors
     * @param id
     * @returns
     */
    onToggleById: (id: string) => void;

    /**
     * Open or close the modal
     * @param isOpen boolean
     * @returns
     */
    setIsModalOpen: (isOpen: boolean) => void;
}
/**
 * Get configurator data from the BFF
 * @param sku vitra SAP SKU
 * @returns
 */
export const getConfiguratorData = async (sku: string) => {
    const { market, language } = getBffLanguageAndCountry();

    const { getConfigurator } = await client.getConfigurator({
        market,
        language,
        sku
    });

    // @todo: error handling
    if (getConfigurator?.__typename === 'QueryGetConfiguratorSuccess') {
        const data = getConfigurator.data as BffConfigurator;
        return data;
    }

    return;
};

export const useConfigurator = create<ConfiguratorStore & ConfiguratorActions>()(
    immer((set, get) => ({
        data: undefined,
        saved: [],
        recommendations: [],
        ui: {
            id: undefined,
            primaryStack: [],
            secondaryStack: [],
            searchEngine: new SearchEngine(en),
            initialized: false
        },
        onClearFilter: () => {
            set((state) => {
                state.ui.filters = undefined;
            });
        },
        onCloseFilter: () => {
            set((state) => {
                state.ui.id = undefined;
                state.ui.filterLayerId = undefined;
            });
        },
        onToggleFilterLayerById: (filterLayerId?: string) => {
            set((state) => {
                state.ui.filterLayerId = state.ui.filterLayerId === filterLayerId ? undefined : filterLayerId;
            });
        },
        onToggleById: (id: string) => {
            set((state) => {
                state.ui.id = id;
            });
        },
        onSetFilter: (key, filter) => {
            set((state) => {
                if (!state.ui.filters) {
                    state.ui.filters = {};
                }
                state.ui.filters[key] = filter as any;
            });
        },
        getFilterCount: (): number => calculateFilterCount(get().ui?.filters),
        getFilteredColors: (ofmlKey: string): BffConfiguratorConfigurationColorItem[] => {
            const { data, ui } = get();
            if (!ui || !ui.id) {
                return [];
            }
            const activeLayerId = ui.id;
            const activeFilterLayer = data?.configurations?.find(
                (config) => config.id === activeLayerId
            ) as BffConfiguratorConfigurationColor;
            const allColors = activeFilterLayer.colors || [];

            if (!ui.filters || !ui.searchEngineMap || !ui.searchEngineMap[ofmlKey]) {
                return allColors;
            }
            return searchForColors(ui.searchEngineMap[ofmlKey], ui.filters);
        },
        onSetTab: (tab) => {
            set((state) => {
                state.ui.tab = tab;
            });
        },
        updateRemoteConfiguration: async (newSku: string) => {
            // if we do a sku change we clear the stack
            const newSkuParts = unmarshalSKU(newSku);
            const currentSku = get().data?.sku;
            const currentSkuParts = currentSku ? unmarshalSKU(currentSku) : { articleNo: '' };

            const canOptimisticallyUpdate = currentSkuParts.articleNo === newSkuParts.articleNo;
            if (canOptimisticallyUpdate) {
                // try to update this optimistically
                const { data } = get();
                const oData = { ...data };
                oData.sku = newSku;
                useViewer.getState().updateViewer(newSku);
                oData.configurations = data?.configurations?.map((config) => {
                    if (config.__typename === 'BffConfiguratorConfigurationAttributes' && config.values) {
                        const values = config.values.map((value) => {
                            return {
                                ...value,
                                selected: value.sku === newSku
                            };
                        });
                        return {
                            ...config,
                            values
                        };
                    }
                    if (config.__typename === 'BffConfiguratorConfigurationColor' && config.colors) {
                        const colors = config.colors.map((color) => {
                            return {
                                ...color,
                                selected: color.sku === newSku
                            };
                        });
                        return {
                            ...config,
                            colors
                        };
                    }
                    return config;
                });

                // optimistically update
                set((state) => {
                    state.data = oData as BffConfigurator;
                });
            }

            set((state) => {
                state.ui.isLoading = true;
                if (!canOptimisticallyUpdate) {
                    state.ui.id = undefined;
                    state.ui.filterLayerId = undefined;
                    state.ui.primaryStack = [];
                    state.ui.secondaryStack = [];
                }
            });

            const newData = await getConfiguratorData(newSku);

            set((state) => {
                // reset the stack on Model change
                if (!canOptimisticallyUpdate) {
                    // @todo: this means we need to re-initalize the search engine
                }

                state.data = newData;
                state.ui.isLoading = false;
            });

            return newData;
        },
        startConfiguration: async (sku: string, recommendations?: WidgetConfiguratorContentRecommendation[]) => {
            try {
                set((state) => {
                    state.ui.isLoading = true;
                });

                const data = await getConfiguratorData(sku);

                // Create search engine
                const searchEngineMap: Record<string, SearchEngine> = {};

                // Populate search engine with color configurations
                data?.configurations?.map((configuration) => {
                    if (configuration.__typename === 'BffConfiguratorConfigurationColor' && configuration.colors) {
                        searchEngineMap[configuration.id] = createSearchEngineFromColors(configuration.colors, 'en');
                    }
                });

                set((state) => {
                    state.data = data as BffConfigurator;
                    state.ui.searchEngineMap = searchEngineMap;
                    state.ui.isLoading = false;
                    if (recommendations) {
                        state.recommendations = recommendations;
                        // @todo propper handling, set before?
                        state.recommendations = [];
                    }
                });

                return data;
            } catch (error) {
                // @todo: error handling, sentry, logs ?
                console.log(error);
            }
        },
        setIsModalOpen: (isOpen: boolean) => {
            set((state) => {
                state.ui.isModalOpen = isOpen;
            });
        }
    }))
);
