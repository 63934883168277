// import { css } from '@linaria/core';

import { BffConfiguratorModularProduct } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Trash as TrashIcon } from '@common/icons';
import { unmarshalSKU } from '@common/utils/sku';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { Panel } from '@components/configurator/components/panel';
import { MODULAR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useModularConfigurator } from '@components/configurator/hooks';
import { DragPayload } from '@components/configurator/widgets/configurator-modular/configurator-modular';
import { DragOverlay as DndKitDragOverlay, useDraggable } from '@dnd-kit/core';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { forwardRef, memo } from 'react';
import { createPortal } from 'react-dom';

const styles = {
    surface: css`
        touch-action: manipulation;
        user-select: none;

        &:hover {
            cursor: grab;
        }

        &:active {
            cursor: grabbing;
        }

        &[aria-disabled='true'] {
            cursor: initial;
            opacity: 0.25;
        }
    `,
    dragOverlay: css`
        pointer-events: none;
    `,
    dragOverlayContent: css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        opacity: 0.66;
    `,
    dragOverlayContentImage: css`
        object-fit: cover;
    `
};

interface DraggableProps {
    dragId: number;
    disabled?: boolean;
    product: BffConfiguratorModularProduct;
}

interface DragOverlayProps {
    className?: string;
    data?: DragPayload | null;
}

const DragOverlay = (props: DragOverlayProps) => {
    const { className } = props;
    const { md } = useMedia();
    // Since the drag overlay is a portal we need to target the correct element
    // otherwise it would render on mobile outside of the screen
    const targetElement = md ? document.body : (document.querySelector('.modular-widget-modal') as Element);

    // if the whole modular configurator component is remounted it can happen that this code executes
    // before the targetElement is available
    if (!targetElement) {
        return null;
    }

    // We need to create a portal for the drag overlay. So it can be positioned anywhere
    // and doesnt interfere with the rest of the app
    return createPortal(
        <DndKitDragOverlay
            dropAnimation={null}
            className={cx(styles.dragOverlay, className)}
            style={{ width: 140, height: 140 }}
            zIndex={MODULAR_MOBILE_MODAL_Z_INDEX + 1}
        >
            <div className={styles.dragOverlayContent}>
                <img
                    className={styles.dragOverlayContentImage}
                    alt=""
                    src={props.data?.image?.src}
                    width={140}
                    height={'auto'}
                />
            </div>
        </DndKitDragOverlay>,
        targetElement
    );
};

const DraggablePresentation = memo(
    forwardRef(function draggablePresentation({ product, disabled, draggableAttributes, isDragging }: any, ref: any) {
        const { md } = useMedia();
        const tileDirection = md ? 'row' : 'column';
        const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

        return (
            <ButtonTile.Item key={product.sku} direction={tileDirection}>
                <ButtonTile.Surface
                    ref={ref}
                    direction={tileDirection}
                    className={styles.surface}
                    draggableAttributes={{ ...draggableAttributes }}
                    disabled={disabled}
                    isDisabled={disabled}
                >
                    <ButtonTile.Image
                        size={thumbnailSize}
                        direction={tileDirection}
                        disableDrag={true}
                        image={(product.image as ButtonTileImageImage) ?? null}
                    >
                        {isDragging && (
                            <ButtonTile.ImageOverlay>
                                <TrashIcon />
                            </ButtonTile.ImageOverlay>
                        )}
                    </ButtonTile.Image>
                    <ButtonTile.Text direction={tileDirection} size={md ? 'large' : 'default'} title={product.name} />
                </ButtonTile.Surface>
            </ButtonTile.Item>
        );
    }),
    () => false
);

const Draggable = (props: DraggableProps) => {
    const { dragId, product } = props;
    const { articleNo, configurationId } = unmarshalSKU(product.sku);
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        // Important: DND requires this to be a string otherwise the id 0 leads to some bugs (eg. Dragoverlay not correctly positioned)
        id: dragId.toString(),
        data: {
            article: articleNo,
            configuration: configurationId,
            image: product.image
        }
    });
    return (
        <DraggablePresentation
            ref={setNodeRef}
            key={product.sku}
            product={product}
            isDragging={isDragging}
            draggableAttributes={{ ...attributes, ...listeners, id: `drag_id_${dragId}` }}
        />
    );
};

export const LayerModularAddProduct = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const dragOverlayPayload = useModularConfigurator((state) => state.ui.dragOverlayPayload);
    const emersyaProducts = useModularConfigurator((state) => state.emersyaProducts);
    const categoryId = useModularConfigurator((state) => state.ui.primaryStack.at(-1))?.replace('add-element-', '');
    const category = useModularConfigurator((state) =>
        state.initialData?.categories?.find((el) => el.id === categoryId)
    );
    const products = useModularConfigurator((state) => state.initialData?.products)?.filter(
        (el) => el.category === categoryId
    );

    const { md } = useMedia();
    const listDirection = md ? 'column' : 'row';

    const renderOptions = () => (
        <ButtonTile.List direction={listDirection}>
            {products
                ?.map((product) => {
                    const emersyaProduct = emersyaProducts.find((ep) => {
                        const { articleNo } = unmarshalSKU(product.sku);
                        return articleNo === ep.article;
                    });

                    return {
                        ...product,
                        available: emersyaProduct?.available
                    };
                })
                .sort((a, b) => {
                    if (a.available && !b.available) {
                        return -1;
                    }
                    if (!a.available && b.available) {
                        return 1;
                    }
                    return 0;
                })
                .map((product, index) => {
                    if (product.available) {
                        return <Draggable key={product.sku} dragId={index} product={product} />;
                    }

                    return <DraggablePresentation key={product.sku} product={product} disabled />;
                })}
        </ButtonTile.List>
    );

    if (md)
        return (
            <>
                <DragOverlay data={dragOverlayPayload} />
                <Panel.Navigation kind="back" onPress={() => popLayer()} />
                <Panel.ScrollArea>
                    <Panel.Title>
                        <Trans>{category?.name}</Trans>
                    </Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <DragOverlay data={dragOverlayPayload} />
            <Panel.Navigation kind="back" onPress={() => popLayer()} appearance="mobile" title={category?.name} />
            <Panel.ScrollArea direction="horizontal">{renderOptions()}</Panel.ScrollArea>
        </>
    );
};
