import { media } from '@common/styles/media';
import { PANEL_HEIGHT, PANEL_WIDTH, TAB_HEIGHT_MOBILE } from '@components/configurator/components/panel/constants';
import { ToolbarDesktop } from '@components/configurator/components/toolbar';
import { Viewer, ViewerModularEmpty } from '@components/configurator/components/viewer';
import { useModularConfigurator, useViewer } from '@components/configurator/hooks';
import { useDroppable } from '@dnd-kit/core';
import { css } from '@linaria/core';

const styles = {
    viewer: css`
        height: calc(100dvh - ${PANEL_HEIGHT - TAB_HEIGHT_MOBILE}px);
        width: 100dvw;
        position: absolute;
        z-index: 0;
        will-change: transform;

        ${media.md} {
            transform: none;
            width: calc(100% - ${PANEL_WIDTH.BASE}px);
            height: 100%;
        }
    `,
    toolbar: css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 31px;
        display: flex;
        z-index: 2;
    `,
    droppable: css`
        width: 100%;
        height: 100%;
    `
};

const Droppable = (props: any) => {
    const { setNodeRef } = useDroppable({
        id: 'droppable'
    });

    return (
        <div className={styles.droppable} ref={setNodeRef}>
            {props.children}
        </div>
    );
};

export const ConfiguratorModularViewer = ({ hasProducts }: { hasProducts: boolean }) => {
    const modularCreateSharedConfiguration = useViewer((state) => state.modularCreateSharedConfiguration);
    const removeHistory = useModularConfigurator((state) => state.removeHistory);
    const history = useModularConfigurator((state) => state.history);
    const hasHistory = history.length > 1;
    const setModularConfiguratorScene = useViewer((state) => state.setModularConfiguratorScene);
    const isLoading = useViewer((state) => !state.loaded);

    // Show the placeholder only if there are no products and the user is on the top level
    const showPlaceholder = isLoading;

    // Show the empty state only if there are no products and the user is not on the top level
    const showEmptyState = !hasProducts;

    const onUndo = () => {
        if (!hasHistory) {
            return;
        }

        const lastElement = history.at(-2);
        if (lastElement) {
            removeHistory();
            setModularConfiguratorScene(lastElement);
        }
    };

    return (
        <div className={styles.viewer}>
            <Droppable>
                <Viewer
                    placeholderUrl={
                        showPlaceholder
                            ? 'https://static-dev.vitra.dev/products/COMCOLLANAGRAM/ca3cbc074b638f1ade2414d948f2dd10ML3PPL0ALBLPC5CB.png'
                            : undefined
                    }
                >
                    {showEmptyState && <ViewerModularEmpty />}
                    {hasProducts && (
                        <ToolbarDesktop
                            className={styles.toolbar}
                            hasDimensions={true}
                            hasResetView={true}
                            hasUndo={hasHistory}
                            onUndo={onUndo}
                            onShare={modularCreateSharedConfiguration}
                        />
                    )}
                </Viewer>
            </Droppable>
        </div>
    );
};
