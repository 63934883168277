import { CloseSmall } from '@common/icons';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { css, cx } from '@linaria/core';
import { Button as DefaultButton } from 'react-aria-components';

const styles = {
    wrapper: css`
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        position: relative;

        ${media.md} {
            max-width: 166px;
        }
    `,
    tableImage: css`
        width: 100%;
        height: auto;
        aspect-ratio: 166 / 108;
        object-fit: cover;
        border-radius: 3px;
    `,
    tableImageTitle: css`
        color: ${colors.black};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%; /* 17.08px */
        padding: 12px 0;

        &::first-letter {
            text-transform: uppercase;
        }
    `,
    tableImageCloseButton: css`
        all: unset;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 9px;
        right: 9px;
        background-color: ${colors.white};
        border-radius: 100%;
        cursor: pointer;

        svg {
            height: 10px;
            width: 10px;
        }

        &[data-focus-visible] {
            outline: none;
            background-color: rgb(0 0 0 / 7%);
        }
    `
};

interface TableImageProps {
    shape?: 'square' | 'rectangle';
    title: string;
    src: string;
    className?: string;
    isRemoveButtonShown?: boolean;
    onRemove?: () => void;
}

export const TableImage = (props: TableImageProps) => {
    const { className, title, src, onRemove, isRemoveButtonShown = true } = props;

    return (
        <div className={cx('table-image', styles.wrapper, className)}>
            <div>
                <img alt={title} src={src} className={styles.tableImage} />
                {isRemoveButtonShown && (
                    <DefaultButton aria-label="Close" className={styles.tableImageCloseButton} onPress={onRemove}>
                        <CloseSmall />
                    </DefaultButton>
                )}
            </div>
            <div className={cx(styles.tableImageTitle)}>{title}</div>
        </div>
    );
};
